import { authClient, API } from '../index';

// Get all categories
export const getCategories = (query) => {
	return authClient().get(`${API}/category${query || ''}`);
};

// Get category by id
export const getCategoryById = (id) => {
	return authClient().get(`${API}/category/${id}`);
};

// Update category
export const updateCategory = async (id, category) => {
	return authClient().put(`${API}/category/${id}`, category);
};

// Create category
export const postCategory = async (category) => {
	return authClient().post(`${API}/category`, category);
};

// Delete category
export const deleteCategory = (id) => {
	return authClient().delete(`${API}/category/${id}`);
};
