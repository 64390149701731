import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, { buttonsStyle } from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import {
	getEnterprises,
	deleteUser,
	validateEnterprise,
} from '../../../../api/user';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';

export function EnterprisesPages() {
	const [data, setData] = useState([]);
	const [selectedEnterprise, selectEnterprise] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		console.log({ elem });
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-enterprise/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && (
					<>
						<Tooltip
							title={
								!elem?.validation_required ? 'Invalidar' : 'Validar'
							}>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									selectEnterprise(elem);
									setOpenConfirmDialog(1);
								}}>
								{elem?.validation_required ? (
									<ToggleOffIcon style={{ color: 'red' }} />
								) : (
									<ToggleOnIcon />
								)}
							</Button>
						</Tooltip>
						<Tooltip title='Eliminar'>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									selectEnterprise(cell);
									setOpenConfirmDialog(2);
								}}>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: 'fullName', text: 'NOmbre', sort: true },
		{ dataField: 'email', text: 'Email', sort: true },
		{ dataField: '_id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getEnterprises()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data.map((item) => ({ ...item, id: item._id })));
					setRefresh(false);
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get users.' })
			);
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de empresas'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-enterprise')}>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`¿Estás seguro de que quieres ${
							selectedEnterprise?.validation_required
								? 'validar'
								: 'invalidar'
						} esta empresa?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							validateEnterprise(
								selectedEnterprise._id,
								!selectedEnterprise?.validation_required
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedEnterprise?.validation_required
													? '¡Validada!'
													: '¡Invalidada!'
											}`,
											customMessage: `Empresa ${
												selectedEnterprise?.validation_required
													? 'validada'
													: 'invalidada'
											} con éxito.`,
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage:
											'No se pudo actualizar la empresa.',
									})
								);
						}}
					/>
					<ConfirmDialog
						title={'¿Estás seguro de que quieres eliminar esta empresa?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							deleteUser(selectedEnterprise)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: '¡Eliminada!',
											customMessage:
												'Empresa eliminada con éxito.',
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage:
											'No se ha podido eliminar la empresa.',
									})
								)
						}
					/>
				</CardBody>
			</Card>
		</>
	);
}
