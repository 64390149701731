import { authClient, API } from '../index';

// Get all users
export const getUsers = () => {
	return authClient().get(`${API}/user?getDisabled=true`);
};

// Get user by id
export const getUserById = (id) => {
	return authClient().get(`${API}/user/${id}`);
};

// Delete user
export const deleteUser = (id) => {
	return authClient().delete(`${API}/user/${id}`);
};

// Create user
export const postUser = async (user) => {
	return authClient().post(`${API}/user`, user);
};

// Update user
export const updateUser = async (id, user) => {
	return authClient().put(`${API}/user/${id}`, user);
};

// Count all admins
export const countUsersByRole = (role) => {
	return authClient().get(`${API}/user/count/${role}`);
};

// Change status user
export const changeStatusUser = async (id, active) => {
	return authClient().put(`${API}/user/change-status/${id}`, { active });
};

// Set user password
export const setPasswordUser = async (token, password) => {
	return authClient().put(`${API}/user/set-password/${token}`, password);
};

// Validate email
export const validateEmail = async (token) => {
	return authClient().put(`${API}/user/validate-email/${token}`);
};

/** ENTERPRISES */

// Get all enterprise users
export const getEnterprises = () => {
	return authClient().get(`${API}/user?getEnterprises=true`);
};

// Validate enterprise account
export const validateEnterprise = async (id, validated) => {
	return authClient().put(`${API}/user/enterprise/change-status/${id}`, {
		validated,
	});
};
