import { uploadSingleFile } from "../files";
import { authClient, API } from "../index";

export const postEventType = async (eventType, icon) => {
	if (icon) {
		const response = await uploadSingleFile(icon, "events");
		eventType.iconURL = response.data.fileURL;
	}
	return authClient().post(`${API}/event-type`, eventType);
};

export const getEventTypes = (query) => {
	return authClient().get(`${API}/event-type${query || ""}`);
};

export const getEventTypeById = (id) => {
	return authClient().get(`${API}/event-type/${id}`);
};

export const updateEventType = async (id, eventType, icon) => {
	if (icon) {
		const response = await uploadSingleFile(icon, "events");
		eventType.iconURL = response.data.fileURL;
	}
	return authClient().put(`${API}/event-type/${id}`, eventType);
};

export const changeStatusEventType = async (id, active) => {
	return authClient().put(`${API}/event-type/change-status/${id}`, {
		active,
	});
};

export const deleteEventType = (id) => {
	return authClient().delete(`${API}/event-type/${id}`);
};
