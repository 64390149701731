import { authClient, API } from '../index';

// Get all questions
export const getQuestions = (query) => {
	return authClient().get(`${API}/question${query || ''}`);
};

// Get question by id
export const getQuestionById = (id) => {
	return authClient().get(`${API}/question/${id}`);
};

// Update question
export const updateQuestion = async (id, question) => {
	return authClient().put(`${API}/question/${id}`, question);
};

// Create question
export const postQuestion = async (question) => {
	return authClient().post(`${API}/question`, question);
};

// Delete question
export const deleteQuestion = (id) => {
	return authClient().delete(`${API}/question/${id}`);
};
