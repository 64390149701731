import {
	Button,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from '@material-ui/core';
import { Map, PersonAdd } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	deleteResource,
	getResourceById,
	getResources,
	postResource,
	updateResource,
} from '../../../../api/resource';
import { getResourceTypologies } from '../../../../api/resourceTypology';
import { getUsers } from '../../../../api/user';
import { GOOGLE_MAPS_API_KEY } from '../../../../utils/constants';
import {
	checkIsEmpty,
	getDistance,
	getNonEmpty,
} from '../../../../utils/helpers';
import { alertError, alertSuccess } from '../../../../utils/logger';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import ResourcesDialog from '../../../components/dialogs/ResourcesDialog';
import MapDialog from '../../../components/dialogs/MapDialog';
import TableDialog from '../../../components/dialogs/TableDialog';
import Editor from '../../../components/editor/Editor';
import { useSkeleton } from '../../../hooks/useSkeleton';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

function getResourceTypologyData(typologies) {
	let data = typologies.map((x) => ({ ...x, title: getNonEmpty(x.title) }));

	data.sort((a, b) => {
		const titleA = a.title.toLowerCase();
		const titleB = b.title.toLowerCase();

		if (titleA < titleB) return -1;
		else if (titleA > titleB) return 1;
		else return 0;
	});

	return data;
}

function getEmptyResource() {
	return {
		title: {},
		description: {},
		phone: null,
		latitude: null,
		longitude: null,
		address: null,
		resourceTypologies: [],
		family: null,
	};
}

export default function EditResourcesPage() {
	const [resource, setResource] = useState(getEmptyResource());
	const [resourceTypologies, setResourceTypologies] = useState([]);
	const [families, setFamilies] = useState([]);

	const [selectedLocation, setSelectedLocation] = useState({
		latitude: null,
		longitude: null,
	});

	const [openMapDialog, setOpenMapDialog] = useState(false);
	const [openTableDialog, setOpenTableDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openResourceDialog, setOpenResourceDialog] = useState(false);

	const resourceId = useParams().id;
	const history = useHistory();

	const [resources, setResources] = useState(
		history.location.state?.data || []
	);
	const [distances, setDistances] = useState([]);

	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!resources?.length)
			getResources()
				.then((res) => {
					if (res.status === 200) setResources(res.data);
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not get resources.',
					})
				);
	}, [resources]);

	useEffect(() => {
		if (distances.length) setOpenResourceDialog(true);
	}, [distances]);

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					let families = res.data.filter((x) => x.role === 'user');
					setFamilies(families);
				}
			})
			.catch((error) => {});
		getResourceTypologies()
			.then((res) => {
				if (res.status === 200)
					setResourceTypologies(getResourceTypologyData(res.data));
			})
			.catch((error) => {});
		if (!resourceId) {
			disableLoadingData();
			return;
		}

		getResourceById(resourceId)
			.then((res) => {
				if (res.status === 200) {
					let resource = {
						...res.data,
						resourceTypologies: res.data?.resourceTypologies.map((x) => x._id),
						family: res.data?.family?._id,
					};

					setResource(resource);
					setSelectedLocation({
						latitude: resource?.latitude,
						longitude: resource?.longitude,
					});
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get resource.' });
				history.push('/resources');
			});
	}, [resourceId, disableLoadingData, history]);

	function saveResource() {
		if (checkIsEmpty(resource.title))
			return alertError({
				error: null,
				customMessage:
					'The title is required in at least one of the languages.',
			});
		if (checkIsEmpty(resource.resourceTypologies))
			return alertError({
				error: null,
				customMessage: 'Al menos una tipología de recurso es necesaria.',
			});
		if (!resourceId)
			postResource(resource)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Resource successfully created.',
						});
						history.push('/resources');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save resource.',
					})
				);
		else
			updateResource(resourceId, resource)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/resources');
					}
				})
				.catch((error) =>
					alertError({ error: error, customMessage: 'Could not save changes.' })
				);
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!resource[element]) resource[element] = {};
			let newText = resource[element];
			newText[lang] = event.target.value;
			setResource({ ...resource, [element]: newText });
		} else setResource({ ...resource, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value === ' ') return;
		if (lang) {
			if (!resource[element]) resource[element] = {};
			let newText = resource[element];
			newText[lang] = value;
			setResource({ ...resource, [element]: newText });
		} else setResource({ ...resource, [element]: value });
	};

	const handlePlaceSelect = (place) => {
		let latitude = place.geometry?.location.lat();
		let longitude = place.geometry?.location.lng();
		let address = place.formatted_address;

		let distances = [];
		resources.forEach((res) => {
			if (res.id === resourceId) return;
			const a = { lat: latitude, lng: longitude };
			const b = { lat: res.latitude, lng: res.longitude };
			const distance = getDistance(a, b);
			if (distance < 100) distances.push({ res, distance });
		});

		setDistances(distances.sort((a, b) => a.distance - b.distance));

		setSelectedLocation({ latitude, longitude, address });
		setResource({ ...resource, latitude, longitude, address });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label='Title'
					value={(resource.title && resource.title[lang]) || ''}
					onChange={handleChange('title', lang)}
					InputLabelProps={{ shrink: true }}
					margin='normal'
					variant='outlined'
					required
				/>
				<br />
				<br />
				<div>Description</div>
				<br />
				<Editor
					body={(resource.description && resource.description[lang]) || ''}
					setBody={(new_body) =>
						handleChangeEditor('description', new_body, lang)
					}
					className='max-height'
					placeholder={'Enter resource description here...'}
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit resource' />
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={renderMultilanguageTabContent}
						/>
						<br />
						<TextField
							id={`phone`}
							label='Teléfono'
							value={resource.phone || ''}
							onChange={handleChange('phone')}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
						/>
						<TextField
							id={`family`}
							label='Familia'
							value={families.find((x) => x._id === resource.family)?.fullName}
							InputLabelProps={{ shrink: true }}
							placeholder='Seleccionar familia'
							margin='normal'
							variant='outlined'
							inputProps={{ readOnly: true }}
							InputProps={
								({ readOnly: true },
								{
									endAdornment: (
										<Tooltip title='Seleccionar familia'>
											<Button onClick={() => setOpenTableDialog(true)}>
												<PersonAdd />
											</Button>
										</Tooltip>
									),
								})
							}
						/>
						<Autocomplete
							multiple
							filterSelectedOptions
							disablePortal
							options={resourceTypologies}
							getOptionLabel={(option) => option.title}
							value={
								resourceTypologies?.filter((x) =>
									resource?.resourceTypologies.includes(x._id)
								) || []
							}
							onChange={(event, selected) => {
								setResource({
									...resource,
									resourceTypologies: selected?.map((x) => x._id),
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin='normal'
									variant='outlined'
									InputLabelProps={{ shrink: true }}
									label='Tipología de recurso'
								/>
							)}
						/>
						<br />
						<br />
						<div>Ubicación *</div>
						<br />
						<div className='d-flex align-items-center location-container'>
							<ReactGoogleAutocomplete
								className='p-3 w-100'
								apiKey={GOOGLE_MAPS_API_KEY}
								options={{
									types: ['geocode', 'establishment'],
									componentRestrictions: { country: 'es' },
								}}
								defaultValue={resource?.address}
								onPlaceSelected={handlePlaceSelect}
							/>
							{((selectedLocation.latitude && selectedLocation.longitude) ||
								(resource.latitude && resource.longitude)) && (
								<Tooltip title='View location'>
									<Button onClick={() => setOpenMapDialog(true)}>
										<Map />
									</Button>
								</Tooltip>
							)}
						</div>
					</CardBody>
				</Card>
				<TableDialog
					title={'Familias'}
					open={openTableDialog}
					setOpen={setOpenTableDialog}
					data={families}
					userTable={true}
					onSelectRow={(selectedRow) => {
						const family = selectedRow ? selectedRow._id : null;
						setResource({ ...resource, family });
						setOpenTableDialog(false);
					}}
				/>
				<MapDialog
					open={openMapDialog}
					setOpen={setOpenMapDialog}
					latitude={selectedLocation.latitude || resource.latitude}
					longitude={selectedLocation.longitude || resource.longitude}
				/>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/resources')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Atrás
					</Button>
					<Button
						onClick={() => saveResource()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Guardar recurso
					</Button>
					{resourceId && user?.role === 'admin' && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Eliminar resource
								</Button>
							</MuiThemeProvider>
							<ConfirmDialog
								title={'¿Estás seguro de que quieres eliminar este recurso?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() =>
									deleteResource(resourceId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Resource deleted successfully',
												});
												history.push('/resources');
											}
										})
										.catch((error) =>
											alertError({
												error: error,
												customMessage: 'Could not delete resource.',
											})
										)
								}
							/>
						</>
					)}
					<ResourcesDialog
						data={distances}
						open={openResourceDialog}
						close={() => setOpenResourceDialog(false)}
					/>
				</div>
			</>
		);
}
