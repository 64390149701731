import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tooltip,
} from "@material-ui/core";
import { AddBox, PersonAdd } from "@material-ui/icons";
import React from "react";
import Table, { buttonsStyle, imageFormatter } from "../tables/table";

const TableDialog = (props) => {
	const {
		title,
		open,
		setOpen,
		data,
		userTable,
		allowRowSelection,
		onSelectRow,
	} = props;

	function buttonFormatter(cell) {
		return (
			<>
				{allowRowSelection !== false && (
					<Tooltip title={"Seleccionar"}>
						<Button
							style={buttonsStyle}
							size="small"
							onClick={() => {
								let _cell = data.find(
									(item) => item._id === cell
								);
								onSelectRow(_cell);
							}}
						>
							{userTable ? (
								<PersonAdd></PersonAdd>
							) : (
								<AddBox></AddBox>
							)}
						</Button>
					</Tooltip>
				)}
			</>
		);
	}

	const columns = userTable
		? [
				{ dataField: "fullName", text: "Full name", sort: true },
				{ dataField: "email", text: "Email", sort: true },

				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ]
		: [
				{ dataField: "iconURL", text: "", formatter: imageFormatter },
				{ dataField: "title", text: "Title", sort: true },
				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ];
	return (
		<Dialog
			fullWidth={true}
			maxWidth="lg"
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="table-dialog"
			className="backdrop"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				{!data || !data.length ? (
					<p>
						{userTable
							? "No se han encontrado familias."
							: "No se han encontrado tipologías."}
					</p>
				) : (
					<Table data={data} columns={columns} />
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
					variant="outlined"
				>
					Cerrar
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default TableDialog;
