import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
	Select,
	FormHelperText,
	MenuItem,
	FormControl,
	InputLabel,
} from '@material-ui/core';
import { Delete, Visibility } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import { SERVER_URL } from '../../../../api';
import {
	deleteStory,
	getStoryById,
	postStory,
	updateStory,
} from '../../../../api/story';
import { getRegions } from '../../../../api/region';
import { checkIsEmpty } from '../../../../utils/helpers';
import { alertError, alertSuccess } from '../../../../utils/logger';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import Editor from '../../../components/editor/Editor';
import { buttonsStyle } from '../../../components/tables/table';
import { useSkeleton } from '../../../hooks/useSkeleton';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

const MenuProps = {
	PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
	getContentAnchorEl: () => null,
};

function getEmptyStory() {
	return {
		title: {},
		content: {},
		summary: {},
		imageURL: null,
		videoURL: null,
		active: false,
		publication_date: new Date().toISOString(),
		regions: [],
	};
}

const getDataSelect = (elements) =>
	elements.map(({ _id, name }) => ({ value: _id, label: name }));

export function EditStoriesPage() {
	const [story, setStory] = useState(getEmptyStory());
	const [regions, setRegions] = useState([]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewImage, setOpenPreviewImage] = useState(false);

	const storyId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getRegions()
			.then((resp) => {
				if (resp.status === 200) setRegions(getDataSelect(resp.data));
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get regions.',
				});
				history.push('/events');
			});
		if (!storyId) {
			disableLoadingData();
			return;
		}
		getStoryById(storyId)
			.then((res) => {
				if (res.status === 200) {
					setStory(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get story.' });
				history.push('/stories');
			});
	}, [storyId, disableLoadingData, history]);

	function saveStory() {
		if (
			checkIsEmpty(story.title) ||
			checkIsEmpty(story.content) ||
			checkIsEmpty(story.summary) ||
			!story.publication_date
		)
			return alertError({
				error: null,
				customMessage:
					'El título, el resumen, el contenido y la fecha de publicación son necesarios en al menos un idioma.',
			});
		if (story.videoURL && !story.videoURL.includes('youtube.com'))
			return alertError({
				error: null,
				customMessage: 'El vídeo tiene que ser de YOUTUBE.',
			});
		if (!storyId)
			postStory(story, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Guardada!',
							customMessage: 'Noticia creada con éxito.',
						});
						history.push('/stories');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save story.',
					})
				);
		else
			updateStory(storyId, story, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Cambios guardados con éxito.',
						});
						history.push('/stories');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save stories.',
					})
				);
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!story[element]) story[element] = {};
			let newStory = story[element];
			newStory[lang] = event.target.value;
			setStory({ ...story, [element]: newStory });
		} else setStory({ ...story, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value === ' ') return;
		if (lang) {
			if (!story[element]) story[element] = {};
			let newStory = story[element];
			newStory[lang] = value;
			setStory({ ...story, [element]: newStory });
		} else setStory({ ...story, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label='Título'
					value={(story.title && story.title[lang]) || ''}
					onChange={handleChange('title', lang)}
					InputLabelProps={{ shrink: true }}
					margin='normal'
					variant='outlined'
					required
				/>
				<br />
				<br />
				<div>Resumen</div>
				<Editor
					body={(story.summary && story.summary[lang]) || ''}
					setBody={(new_body) =>
						handleChangeEditor('summary', new_body, lang)
					}
					className='max-height'
					placeholder={
						'Introduce un breve resumen de la noticia aquí... (una línea aprox.)'
					}
				/>
				<br />
				<div>Contenido</div>
				<Editor
					body={(story.content && story.content[lang]) || ''}
					setBody={(new_body) =>
						handleChangeEditor('content', new_body, lang)
					}
					className='max-height'
					placeholder={'Introduce el contenido de la noticia aquí...'}
				/>
				<br />
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar noticia' />
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={renderMultilanguageTabContent}
						/>
						<br />
						<TextField
							id='publication_date'
							label='Publication date (España):'
							type='date'
							value={
								story.publication_date &&
								story.publication_date?.includes('T')
									? story.publication_date.split('T')[0]
									: story.publication_date
							}
							onChange={handleChange('publication_date')}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
						/>
						<br />
						<label htmlFor={'upload-image'}>
							<input
								style={{ display: 'none' }}
								id={'upload-image'}
								name={'upload-image'}
								type='file'
								accept={'image/*'}
								onChange={(e) => setSelectedImage(e.target.files[0])}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedImage || story.imageURL
									? 'Cambiar imagen'
									: 'Subir imagen'}
							</Button>
						</label>
						{(selectedImage || story.imageURL) && (
							<>
								<Tooltip title={'Vista previa de imagen'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewImage(true)}
										style={{ ...buttonsStyle }}>
										<Visibility />
									</Button>
								</Tooltip>
								<Tooltip title='Eliminar'>
									<Button
										size='small'
										style={{
											...buttonsStyle,
											marginRight: '15px',
										}}
										onClick={() => {
											setSelectedImage(null);
											setStory({ ...story, imageURL: null });
										}}>
										<Delete />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Vista previa de imagen'}
									open={openPreviewImage}
									setOpen={setOpenPreviewImage}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${story.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: story.imageURL
										? story.imageURL.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br />
						<br />
						<TextField
							id={`Vídeo`}
							label='Vídeo'
							value={story.videoURL}
							onChange={handleChange('videoURL')}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
						/>
						<FormHelperText>
							{
								'Tiene que ser la dirección completa del vídeo de YOUTUBE.'
							}
						</FormHelperText>
						<br />
						<br />
						<FormControl style={{ width: '100%' }} className='mb-2'>
							<InputLabel id='demo-simple-select-standard-label'>
								{'Regiones *'}
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								multiple
								value={story.regions || []}
								onChange={handleChange('regions')}
								MenuProps={MenuProps}>
								{regions?.map(({ value, label }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{
									'Solo los usuarios de las regiones seleccionadas tendrán acceso a estas noticias.'
								}
							</FormHelperText>
						</FormControl>
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={story?.active}
									onChange={() =>
										setStory({
											...story,
											active: !story?.active,
										})
									}
									name='checkActive'
								/>
							}
							label='Activa'
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/stories')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Atrás
					</Button>
					<Button
						onClick={() => saveStory()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Guardar noticia
					</Button>
					{storyId && user?.role === 'admin' && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Eliminar noticia
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}
								/>
							</MuiThemeProvider>
							<ConfirmDialog
								title={
									'¿Estás seguro de que quieres eliminar esta noticia?'
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() =>
									deleteStory(storyId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: '¡Eliminada!',
													customMessage:
														'Noticia eliminada con éxito',
												});
												history.push('/stories');
											}
										})
										.catch((error) =>
											alertError({
												error: error,
												customMessage:
													'Could not delete story.',
											})
										)
								}
							/>
						</>
					)}
				</div>
			</>
		);
}
