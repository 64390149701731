import { Button, Tooltip } from '@material-ui/core';
import { ToggleOff, ToggleOn } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {
	deleteStory,
	getStories,
	changeStatusStory,
} from '../../../../api/story';
import { getNonEmpty } from '../../../../utils/helpers';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import Table, {
	buttonsStyle,
	dateFormatter,
} from '../../../components/tables/table';

const getData = (stories) =>
	stories.map((story) => ({
		...story,
		title: getNonEmpty(story.title),
		id: story._id,
	}));

export function StoriesPage() {
	const [data, setData] = useState([]);
	const [selectedStory, setSelectedStory] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-story/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && (
					<>
						<Tooltip title={elem?.active ? 'Desactivar' : 'Activar'}>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setSelectedStory(elem);
									setOpenConfirmDialog(3);
								}}>
								{elem?.active ? (
									<ToggleOff />
								) : (
									<ToggleOn style={{ color: 'red' }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title='Eliminar'>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setSelectedStory(elem);
									setOpenConfirmDialog(2);
								}}>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: 'title', text: 'Título', sort: true },
		{
			dataField: 'publication_date',
			text: 'Fecha de publicación',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getStories('?getDisabled=true')
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get stories.' })
			);
	}, [refresh]);

	return (
		<Card>
			<CardHeader title='Listado de noticias'>
				<CardHeaderToolbar>
					<button
						type='button'
						className='btn btn-primary'
						onClick={() => history.push('/edit-story')}>
						{'Añadir'}
					</button>
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				<Table data={data} columns={columns} />
				<ConfirmDialog
					title={'¿Estás seguro de que quieres eliminar esta noticia?'}
					open={openConfirmDialog === 2}
					setOpen={setOpenConfirmDialog}
					onConfirm={() => {
						deleteStory(selectedStory?.id)
							.then((res) => {
								if (res.status === 204 || res.status === 200) {
									alertSuccess({
										title: '¡Eliminada!',
										customMessage: 'Noticia eliminada con éxito.',
									});
									setRefresh(true);
								}
							})
							.catch((error) =>
								alertError({
									error: error,
									customMessage: 'Could not remove story.',
								})
							);
					}}
				/>
				<ConfirmDialog
					title={`¿Estás seguro de que quieres ${
						selectedStory?.active ? 'desactivar' : 'activar'
					} esta noticia?`}
					open={openConfirmDialog === 3}
					setOpen={setOpenConfirmDialog}
					onConfirm={() => {
						changeStatusStory(selectedStory.id, !selectedStory?.active)
							.then((res) => {
								if (res.status === 200) {
									alertSuccess({
										title: `${
											selectedStory?.active ? '¡Desactivada!' : '¡Activada!'
										}`,
										customMessage: `Noticia ${
											selectedStory?.active ? 'desactivada' : 'activada'
										} con éxito`,
									});
									setRefresh(true);
								}
							})
							.catch((error) =>
								alertError({
									error: error,
									customMessage: `Could not ${
										selectedStory?.active ? 'disable' : 'enable'
									} story.`,
								})
							);
					}}
				/>
			</CardBody>
		</Card>
	);
}
