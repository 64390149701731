import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { setPasswordUser } from '../../api/user/index';
import '../../customStyles/landing.css';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';

const initialValues = {
	password: '',
	passwordConfirmation: '',
};

function SetPasswordPage() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');
	const [isSuccess, setIsSuccess] = useState(false);

	const MESSAGES = {
		minLength: 'Mínimo 7 caracteres',
		maxLength: 'Máximo 50 caracteres',
		requiredPassword: 'El campo contraseña está vacío.',
		requiredPasswordConfirmation:
			'El campo confirmación de contraseña está vacío.',
		passwordsDontMatch: 'Las contraseñas no coinciden.',
		error: 'Algo salió mal. Comprueba que la dirección es correcta',
		tokenExpired: 'La solicitud de cambio de contraseña ha caducado',
		success: 'Contraseña configurada con éxito',
	};

	const SetPasswordSchema = Yup.object().shape({
		password: Yup.string()
			.min(7, MESSAGES.minLength)
			.max(50, MESSAGES.maxLength)
			.required(MESSAGES.requiredPassword),
		passwordConfirmation: Yup.string()
			.min(7, MESSAGES.minLength)
			.max(50, MESSAGES.maxLength)
			.required(MESSAGES.requiredPasswordConfirmation)
			.when('password', {
				is: (val) => val && val.length > 0,
				then: Yup.string().oneOf(
					[Yup.ref('password')],
					MESSAGES.passwordsDontMatch
				),
			}),
	});

	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname])
			return 'is-invalid';
		else if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid';
		} else if (fieldname === 'resultContainer')
			return isSuccess ? 'alert-info' : 'alert-custom';
		else if ((fieldname === 'sub' || fieldname === 'passwordForm') && isSuccess)
			return 'd-none';
		return '';
	};

	const formik = useFormik({
		initialValues,
		validationSchema: SetPasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				setPasswordUser(token, {
					password: Buffer.from(values.password).toString('base64'),
				})
					.then((response) => {
						if (response.status === 200) {
							setIsSuccess(true);
							setStatus(MESSAGES.success);
						} else setStatus(MESSAGES.error);
					})
					.catch((error) => {
						const errorMessage = error.response?.data?.message;
						if (errorMessage === 'TOKEN_EXPIRED')
							setStatus(MESSAGES.tokenExpired);
						else setStatus(MESSAGES.error);
					});
			} catch (error) {
				setStatus(MESSAGES.error);
			}
			setSubmitting(false);
		},
	});

	return (
		<>
			<div className='centered-container'>
				<div className='text-center mb-lg-10'>
					<img
						alt='logo'
						className='max-h-77px max-w-275px'
						src={toAbsoluteUrl('/media/logo/logo.png')}
					/>
					<h1 className='display-5 mt-20'>
						{isSuccess
							? 'Contraseña restablecida'
							: '¡Restablecer la contraseña!'}
					</h1>
				</div>
				<div
					className={`text-center text-muted font-weight-bold mt-5 mb-5 ${getInputClasses(
						'sub'
					)} `}>
					{`Elige una nueva contraseña`}
				</div>
				{formik.status && (
					<div
						className={`mb-10 px-0 alert w-75 align-self-center ${getInputClasses(
							'resultContainer'
						)} text-center alert-light-danger alert-dismissible`}>
						<div className='alert-text font-weight-bold'>
							{formik.status}
						</div>
					</div>
				)}
				<form
					onSubmit={formik.handleSubmit}
					className={`form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp ${getInputClasses(
						'passwordForm'
					)}`}>
					{/* begin: Password */}
					<div className='form-group fv-plugins-icon-container'>
						<input
							type='password'
							placeholder='Nueva contraseña'
							className={`form-control form-control-solid h-auto w-auto py-5 px-6 ml-auto mr-auto ${getInputClasses(
								'password'
							)}`}
							name='password'
							{...formik.getFieldProps('password')}
						/>
						{formik.touched.password && formik.errors.password ? (
							<div className='invalid-feedback text-center'>
								{formik.errors.password}
							</div>
						) : null}
					</div>
					{/* end: Password */}
					{/* begin: Confirm Password */}
					<div className='form-group fv-plugins-icon-container'>
						<input
							type='password'
							placeholder='Confirma la contraseña'
							className={`form-control form-control-solid h-auto w-auto py-5 px-6 ml-auto mr-auto ${getInputClasses(
								'passwordConfirmation'
							)}`}
							name='passwordConfirmation'
							{...formik.getFieldProps('passwordConfirmation')}
						/>
						{formik.touched.passwordConfirmation &&
						formik.errors.passwordConfirmation ? (
							<div className='invalid-feedback text-center'>
								{formik.errors.passwordConfirmation}
							</div>
						) : null}
					</div>
					{/* end: Confirm Password */}
					<div className='form-group d-flex flex-wrap flex-center'>
						<button
							id='kt_login_forgot_submit'
							type='submit'
							className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'
							disabled={formik.isSubmitting}>
							<span>{'Restablecer'}</span>
							{formik.isSubmitting && (
								<span className='ml-3 spinner spinner-white' />
							)}
						</button>
					</div>
				</form>

				<div>
					<h2 className='display-8 align-self-center text-center font-weight-bold mt-10 mb-10'>
						{'Descárgate la app para empezar'}
					</h2>
					<div className='store-container'>
						<a
							href='https://play.google.com/store/apps/details?id=com.proxare.android'
							target='_blank'
							rel='noreferrer'>
							<button
								id='kt_login_signin_submit'
								type='button'
								className={
									'store-button android-button font-weight-bold px-9 py-4'
								}>
								<AndroidIcon />
								{'Google Play'}
							</button>
						</a>
						<a
							href='https://apps.apple.com/vn/app/proxare/id6470037589'
							target='_blank'
							rel='noreferrer'>
							<button
								id='kt_login_signin_submit'
								type='button'
								className={
									'store-button ios-button font-weight-bold px-9 py-4'
								}>
								<AppleIcon />
								{'App Store'}
							</button>
						</a>
					</div>
				</div>
			</div>
		</>
	);
}

export default SetPasswordPage;
