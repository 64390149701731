import { uploadSingleFile } from '../files';

import { authClient, API } from '../index';

// Count all stories
export const countStories = () => {
	return authClient().get(`${API}/story/count`);
};

// Get all stories
export const getStories = (query) => {
	return authClient().get(`${API}/story${query || ''}`);
};

// Get story by id
export const getStoryById = (id) => {
	return authClient().get(`${API}/story/${id}`);
};

// Update story
export const updateStory = async (id, story, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'stories');
		story.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/story/${id}`, story);
};

// Create story
export const postStory = async (story, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'stories');
		story.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/story`, story);
};

// Change story status
export const changeStatusStory = async (id, active) => {
	return authClient().put(`${API}/story/change-status/${id}`, { active });
};

// Delete story
export const deleteStory = (id) => {
	return authClient().delete(`${API}/story/${id}`);
};
