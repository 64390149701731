import { Button } from "@material-ui/core";
import ForumIcon from "@material-ui/icons/Forum";
import PersonIcon from "@material-ui/icons/Person";
import ReplyIcon from "@material-ui/icons/Reply";
import ScheduleIcon from "@material-ui/icons/Schedule";
import React, { useEffect, useState } from "react";
import InputEmoji from "react-input-emoji";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
	deleteReply,
	getRepliesByThread,
	postReply,
} from "../../../../api/reply";
import { getThreadById } from "../../../../api/thread";
import { alertError, alertSuccess } from "../../../../utils/logger";
import {
	dateFormatter,
	validatedFormatter,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Delete } from "@material-ui/icons";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";

function getReplies(parentId, data) {
	if (!parentId || !data?.length) return [];
	const replies = data.filter((r) => r.parent === parentId) || [];
	return replies;
}

function RepliesList({
	replies,
	parentReply,
	setParentReply,
	setDeleteReplyId,
	setOpenConfirmDialog,
	newMessageReply,
	setNewMessageReply,
	handleSend,
	childReplies,
	newReply,
}) {
	if (!replies) return <></>;
	return replies?.map((reply) => (
		<div key={reply._id} className="reply-stack">
			<>
				<div
					key={reply._id}
					id={reply._id}
					className={
						"reply mb-5 " +
						(newReply === reply._id ? "new-reply" : "")
					}
				>
					<div className="reply-img-container">
						<img
							className="reply-img"
							alt={reply.sender?.fullName}
							src={
								reply.sender?.role === "admin"
									? toAbsoluteUrl(
											"/media/svg/icons/admin-profile-icon.png"
									  )
									: toAbsoluteUrl(
											"/media/svg/icons/profile-icon.png"
									  )
							}
							width={50}
						/>
					</div>
					<div className="reply-content">
						<h5>
							{reply.sender?.fullName}
							<span
								className="mx-3"
								style={{ fontSize: "small", color: "gray" }}
							>
								{dateFormatter(reply?.createdAt)}
							</span>
							<span
								className="reply-button pointer"
								onClick={() => {
									setParentReply(reply._id);
									setNewMessageReply("");
								}}
							>
								<ReplyIcon /> Reply
							</span>
							<span
								className="delete-reply-button pointer"
								onClick={() => {
									setDeleteReplyId(reply._id);
									setOpenConfirmDialog(true);
								}}
							>
								<Delete />
							</span>
						</h5>
						<p>{reply.text}</p>
					</div>
				</div>
				<div className="nested-replies-stack">
					<div className="nested-replies">
						<RepliesList
							replies={getReplies(reply._id, childReplies)}
							parentReply={parentReply}
							setParentReply={setParentReply}
							setDeleteReplyId={setDeleteReplyId}
							setOpenConfirmDialog={setOpenConfirmDialog}
							newMessageReply={newMessageReply}
							setNewMessageReply={setNewMessageReply}
							handleSend={handleSend}
							childReplies={childReplies}
							newReply={newReply}
						/>
					</div>
				</div>
				{parentReply === reply._id && (
					<>
						<div className="reply-sender">
							<InputEmoji
								value={newMessageReply}
								onChange={(message) =>
									setNewMessageReply(message)
								}
							/>
							<button
								type="button"
								className="btn btn-primary"
								onClick={handleSend(true)}
							>
								Send
							</button>
						</div>
					</>
				)}
			</>
		</div>
	));
}

export default function ViewThreadsPage() {
	const [thread, setThread] = useState(null);
	const [deleteReplyId, setDeleteReplyId] = useState(null);

	const [rootReplies, setRootReplies] = useState([]);
	const [childReplies, setChildReplies] = useState([]);
	const [parentReply, setParentReply] = useState(null);
	const [newReply, setNewReply] = useState(null);
	const [newMessage, setNewMessage] = useState("");
	const [newMessageReply, setNewMessageReply] = useState("");
	const [showReplies, setShowReplies] = useState(true);
	const [numReplies, setNumReplies] = useState(0);
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);

	const threadId = useParams().id;
	const history = useHistory();
	const [refresh, setRefresh] = useState(true);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!threadId) {
			disableLoadingData();
			alertError({ error: null, customMessage: "Could not get thread." });
			history.push("/threads");
			return;
		}
		getThreadById(threadId)
			.then((res) => {
				if (res.status === 200) {
					setThread(res.data);
					setNumReplies(res.data.numReplies);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get thread.",
				});
				history.push("/threads");
			});
		getRepliesByThread(threadId)
			.then((res) => {
				if (res.status === 200) {
					setRootReplies(
						res.data.filter(
							(item) => !item.parent || item.parent === null
						)
					);
					setChildReplies(
						res.data.filter((item) => item.parent !== null)
					);
					setRefresh(false);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get thread replies.",
				});
				history.push("/threads");
			});
	}, [threadId, disableLoadingData, history, refresh]);

	const handleSend = (replying) => async (e) => {
		e.preventDefault();
		let reply = {
			sender: user._id,
			text: newMessage,
			thread: thread._id,
		};
		if (replying) {
			if (parentReply)
				reply = {
					...reply,
					parent: parentReply,
					text: newMessageReply,
				};
			else
				alertError({
					error: null,
					customMessage: "Could not send reply.",
				});
		}
		try {
			const { data } = await postReply(reply);
			if (replying) {
				setNewMessageReply("");
				setChildReplies(childReplies.concat({ ...data, sender: user }));
			} else {
				setNewMessage("");
				setRootReplies(rootReplies.concat({ ...data, sender: user }));
			}
			setNumReplies(numReplies + 1);
			setParentReply(null);
			setNewReply(data._id);
			setTimeout(() => {
				setNewReply(null);
			}, 2000);
		} catch (err) {
			alertError({ error: err, customMessage: "Could not send reply." });
		}
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title={thread?.title || "----"}>
						<CardHeaderToolbar>
							<span className="mx-2 badge">
								{thread?.topic.fullName}
							</span>
							<span
								className="badge badge-light mx-2"
								style={{ fontSize: "small" }}
							>
								Validado {validatedFormatter(thread?.validated)}
							</span>
							<span
								className="badge badge-light mx-2"
								style={{ fontSize: "small" }}
							>
								<PersonIcon /> {thread?.createdBy.fullName}
							</span>
							<span
								className="badge badge-light"
								style={{ fontSize: "small" }}
							>
								<ScheduleIcon />{" "}
								{dateFormatter(thread?.createdAt)}
							</span>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						<div
							dangerouslySetInnerHTML={{ __html: thread?.text }}
						/>
						<br />
						<br />
						<span
							className={`badge badge-${
								showReplies ? "primary" : "dark"
							} pointer`}
							style={{ fontSize: "small" }}
							onClick={() => setShowReplies(!showReplies)}
						>
							<ForumIcon /> {numReplies} replies
						</span>
						<br />
						<br />
						{showReplies && (
							<RepliesList
								replies={rootReplies}
								parentReply={parentReply}
								setParentReply={setParentReply}
								setDeleteReplyId={setDeleteReplyId}
								setOpenConfirmDialog={setOpenConfirmDialog}
								newMessageReply={newMessageReply}
								setNewMessageReply={setNewMessageReply}
								handleSend={handleSend}
								childReplies={childReplies}
								newReply={newReply}
							/>
						)}
					</CardBody>
					<CardFooter>
						<div className="reply-sender">
							<InputEmoji
								value={newMessage}
								onChange={(message) => setNewMessage(message)}
							/>
							<button
								type="button"
								className="btn btn-primary"
								onClick={handleSend(false)}
							>
								Send
							</button>
						</div>
					</CardFooter>
				</Card>
				<ConfirmDialog
					title={
						"¿Estás seguro de que quieres eliminar este mensaje?"
					}
					children={"Esto también eliminará todas sus respuestas"}
					open={openConfirmDialog}
					setOpen={setOpenConfirmDialog}
					onConfirm={() => {
						deleteReply(deleteReplyId)
							.then((res) => {
								if (res.status === 204 || res.status === 200) {
									alertSuccess({
										title: "Deleted!",
										customMessage:
											"Reply removed successfully.",
									});
									setRefresh(true);
								}
							})
							.catch((error) => {
								alertError({
									error: error,
									customMessage: "Could not remove reply.",
								});
							});
					}}
				/>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/threads")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
				</div>
			</>
		);
}
