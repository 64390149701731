import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	deleteTopic,
	getTopicById,
	postTopic,
	updateTopic,
} from '../../../../api/topic';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { useSkeleton } from '../../../hooks/useSkeleton';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

function getEmptyTopic() {
	return { title: {}, active: true, restricted: false };
}

export function EditTopicsPage() {
	const [topic, setTopic] = useState(getEmptyTopic());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const topicId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!topicId) {
			disableLoadingData();
			return;
		}
		getTopicById(topicId)
			.then((res) => {
				if (res.status === 200) {
					setTopic(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get topic.',
				});
				history.push('/topics');
			});
	}, [topicId, disableLoadingData, history]);

	function saveTopic() {
		if (!topicId)
			postTopic(topic)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Topic successfully created.',
						});
						history.push('/topics');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save topic.',
					});
				});
		else
			updateTopic(topicId, topic)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/topics');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!topic[element]) topic[element] = {};
			let newText = topic[element];
			newText[lang] = event.target.value;
			setTopic({ ...topic, [element]: newText });
		} else
			setTopic({
				...topic,
				[element]: event.target.value,
			});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label='Título'
					value={(topic.title && topic.title[lang]) || ''}
					onChange={handleChange('title', lang)}
					InputLabelProps={{ shrink: true }}
					margin='normal'
					variant='outlined'
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar tópico' />
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={renderMultilanguageTabContent}
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={topic.restricted}
									onChange={() =>
										setTopic({
											...topic,
											restricted: !topic.restricted,
										})
									}
									name='checkRestricted'
								/>
							}
							label='Limitado a empresas'
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={topic.active}
									onChange={() =>
										setTopic({ ...topic, active: !topic.active })
									}
									name='checkActive'
								/>
							}
							label='Activo'
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/topics')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Atrás
					</Button>
					<Button
						onClick={() => saveTopic()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Guardar tópico
					</Button>
					{topicId && user?.role === 'admin' && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Eliminar tópico
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}
								/>
							</MuiThemeProvider>
							<ConfirmDialog
								title={
									'¿Estás seguro de que quieres eliminar este tópico?'
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() =>
									deleteTopic(topicId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: '¡Eliminado!',
													customMessage:
														'Tópico eliminado con éxito.',
												});
												history.push('/topics');
											}
										})
										.catch((error) =>
											alertError({
												error: error,
												customMessage:
													'No se ha podido eliminar el tópico.',
											})
										)
								}
							/>
						</>
					)}
				</div>
			</>
		);
}
