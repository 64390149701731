import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import { changeStatusTopic, deleteTopic, getTopics } from '../../../../api/topic';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import Table, {
	buttonsStyle,
	dateFormatter,
} from '../../../components/tables/table';
import { getNonEmpty } from '../../../../utils/helpers';

function getData(topics) {
	let data = [];
	for (let i = 0; i < topics.length; ++i) {
		const elem = {};
		elem.title = getNonEmpty(topics[i].title);
		elem.createdAt = topics[i].createdAt;
		elem.id = topics[i]._id;
		elem.active = topics[i].active;
		elem.restricted = topics[i].restricted ? 'Sí' : 'No';
		data = data.concat(elem);
	}
	return data;
}

export function TopicsPage() {
	const [data, setData] = useState([]);
	const [topicId, setTopicId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-topic/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && (
					<>
						<Tooltip title={elem?.active ? 'Desactivar' : 'Activar'}>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setTopicId(elem);
									setOpenConfirmDialog(1);
								}}>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: 'red' }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title='Eliminar'>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setTopicId(cell);
									setOpenConfirmDialog(2);
								}}>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: 'title', text: 'Título', sort: true },
		{
			dataField: 'createdAt',
			text: 'Fecha',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'restricted', text: 'Limitado a empresas', sort: true },
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getTopics('?getDisabled=true')
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) =>
				alertError({
					error: error,
					customMessage: 'No se han podido obtener los tópicos.',
				})
			);
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de tópicos'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-topic')}>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`¿Estás seguro de que quieres ${
							topicId?.active ? 'desactivar' : 'activar'
						} este tópico?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							changeStatusTopic(topicId.id, !topicId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												topicId?.active
													? '¡Desactivado!'
													: '¡Activado!'
											}`,
											customMessage: `Tópico ${
												topicId?.active
													? 'desactivado'
													: 'activado'
											} con éxito`,
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage: `No se ha podido ${
											topicId?.active
												? 'desactivar'
												: 'activar'
										} el tópico.`,
									})
								)
						}
					/>
					<ConfirmDialog
						title={'¿Estás seguro de que quieres eliminar este tópico?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							deleteTopic(topicId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: '¡Eliminado!',
											customMessage:
												'Tópico eliminado con éxito.',
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage:
											'No se ha podido eliminar el tópico.',
									})
								)
						}
					/>
				</CardBody>
			</Card>
		</>
	);
}
