import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  CardContent,
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { getSurveyEntriesWithDetails } from "../../../../api/survey";
import { alertError } from "../../../../utils/logger";
import jsPDF from "jspdf";
import "jspdf-autotable";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: theme.shadows[4],
    },
  },
  answerSection: {
    marginBottom: theme.spacing(1),
  },
  question: {
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  button: {
    backgroundColor: "#662483",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#531a6d",
    },
    marginLeft: theme.spacing(2),
  },
}));

export function SurveyResponsesPage() {
  const classes = useStyles();
  const { id: surveyId } = useParams();
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [survey, setSurvey] = useState(null);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);
  const rowsPerPage = 6;

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchSurveyAndEntries = async () => {
      try {
        const surveyEntriesRes = await getSurveyEntriesWithDetails(surveyId);
        if (surveyEntriesRes.status === 200) {
          const surveyData =
            surveyEntriesRes.data.length > 0
              ? surveyEntriesRes.data[0].survey
              : null;
          setEntries(surveyEntriesRes.data);
          setSurvey(surveyData);
        } else {
          setError("Failed to fetch survey data.");
          alertError({ customMessage: "Error fetching survey data." });
        }
      } catch (err) {
        setError(err.message);
        alertError({
          error: err,
          customMessage: "Error fetching survey data.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyAndEntries();
  }, [surveyId]);

  useEffect(() => {
    if (user?.role !== "admin") {
      alertError({
        customMessage: "No tienes permiso para acceder a esta página.",
      });
      history.push("/unauthorized");
    }
  }, [user, history]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const getAnswerText = (question, answerValue) => {
    if (!question || !question.type) return "N/A";

    switch (question.type) {
      case "open":
        return answerValue || "N/A";

      case "boolean":
        if (answerValue === "1") return "Sí";
        if (answerValue === "0") return "No";
        return "N/A";

      case "test": {
        const index = parseInt(answerValue, 10);
        if (question.answers && question.answers[index]) {
          return question.answers[index].es;
        }

        return `Respuesta nº: ${answerValue}`;
      }

      case "test-multiple": {
        if (!answerValue) return "N/A";

        const counts = answerValue.split(",").map((num) => parseInt(num, 10));
        if (!counts || counts.length === 0) return "N/A";

        const selectedAnswers = counts
          .map((count, index) => {
            if (count > 0 && question.answers && question.answers[index]) {
              return `${question.answers[index].es}`;
            }
            return null;
          })
          .filter((item) => item !== null);

        return selectedAnswers.length > 0 ? selectedAnswers.join(", ") : "N/A";
      }

      case "numeric": {
        if (
          answerValue === null ||
          answerValue === undefined ||
          answerValue === ""
        )
          return "N/A";
        return `${answerValue}`;
      }

      default:
        return "N/A";
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const filteredEntries = entries.filter((entry) =>
    entry.user?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredEntries.length / rowsPerPage);
  const paginatedEntries = filteredEntries.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const margin = 14;
    const pageHeight = doc.internal.pageSize.getHeight();
    const lineHeight = 10;

    filteredEntries.forEach((entry, entryIndex) => {
      if (entryIndex !== 0) {
        doc.addPage();
      }

      doc.setFontSize(16);
      doc.text(
        `Respuestas para: ${survey?.name?.es || "Encuesta"}`,
        margin,
        20
      );

      doc.setFontSize(12);
      doc.text(
        `Usuario: ${entry.user?.fullName || "Usuario Desconocido"}`,
        margin,
        30
      );
      doc.text(`Completado: ${entry.completed ? "Sí" : "No"}`, margin, 40);

      let yOffset = 50;

      survey.questions.forEach((question) => {
        const answerObj = entry.answers.find(
          (a) => a.question._id === question._id
        );
        const answerValue = answerObj ? answerObj.answer : null;
        let answerText = getAnswerText(question, answerValue);

        if (answerText === "N/A" && question.type === "test-multiple") {
          answerText = `N/A Respuesta del usuario ${entryIndex + 1}`;
        }

        doc.setFont("helvetica", "bold");
        const questionText = question.name?.es.endsWith("?")
          ? question.name.es
          : `${question.name?.es}?`;
        doc.text(questionText, margin, yOffset);
        yOffset += lineHeight;

        doc.setFont("helvetica", "normal");
        const splitAnswer = doc.splitTextToSize(
          answerText,
          doc.internal.pageSize.getWidth() - margin * 2
        );
        doc.text(splitAnswer, margin, yOffset);
        yOffset += splitAnswer.length * lineHeight;

        if (yOffset > pageHeight - margin) {
          doc.addPage();
          yOffset = margin;
        }
      });

      doc.setFontSize(10);
      doc.text(
        `Página ${entryIndex + 1} de ${filteredEntries.length}`,
        doc.internal.pageSize.getWidth() - margin,
        pageHeight - 10
      );
    });

    doc.save(`Respuestas_${survey?.name?.es || "Encuesta"}.pdf`);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={`Respuestas para: ${survey?.name?.es || "Encuesta"}`}
        >
          <CardHeaderToolbar>
            <Button
              variant="contained"
              className={classes.button}
              startIcon={<ArrowBack />}
              onClick={() => history.push("/surveys")}
            >
              Volver
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleDownloadPDF}
            >
              Descargar PDF
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardContent>
          {/* Search Bar */}
          <TextField
            label="Buscar por Usuario"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={handleSearchChange}
          />

          {filteredEntries.length === 0 ? (
            <Typography>No hay respuestas para esta encuesta.</Typography>
          ) : (
            <>
              <Grid container spacing={3}>
                {paginatedEntries.map((entry, entryIndex) => (
                  <Grid item xs={12} md={6} key={entry._id}>
                    <Card className={classes.card}>
                      <CardHeader
                        title={entry.user?.fullName || "Usuario Desconocido"}
                        subheader={
                          entry.completed ? "Completado" : "No completado"
                        }
                      />
                      <CardContent>
                        {survey.questions.map((question) => {
                          const answerObj = entry.answers.find(
                            (a) => a.question._id === question._id
                          );
                          const answerValue = answerObj
                            ? answerObj.answer
                            : null;
                          let answerText = getAnswerText(question, answerValue);

                          if (
                            answerText === "N/A" &&
                            question.type === "test-multiple"
                          ) {
                            answerText = `N/A Respuesta del usuario ${entryIndex +
                              1}`;
                          }

                          return (
                            <div
                              key={question._id}
                              className={classes.answerSection}
                            >
                              <Typography
                                variant="subtitle1"
                                className={classes.question}
                              >
                                {question.name?.es.endsWith("?")
                                  ? question.name.es
                                  : `${question.name?.es}?`}
                              </Typography>
                              <Typography variant="body2">
                                {answerText}
                              </Typography>
                            </div>
                          );
                        })}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {/* Pagination Controls */}
              {totalPages > 1 && (
                <div className={classes.paginationContainer}>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}
