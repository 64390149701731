import { API, authClient } from "../index";

export const postTopic = async (topic) => {
	return authClient().post(`${API}/topic`, topic);
};

export const getTopics = (query) => {
	return authClient().get(`${API}/topic${query || ""}`);
};

export const countTopics = async () => {
	return authClient().get(`${API}/topic/count`);
};

export const getTopicById = (id) => {
	return authClient().get(`${API}/topic/${id}`);
};

export const updateTopic = async (id, topic) => {
	return authClient().put(`${API}/topic/${id}`, topic);
};

export const changeStatusTopic = async (id, active) => {
	return authClient().put(`${API}/topic/change-status/${id}`, { active });
};

export const deleteTopic = (id) => {
	return authClient().delete(`${API}/topic/${id}`);
};
