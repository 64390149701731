import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteRegion,
	getRegionById,
	postRegion,
	updateRegion,
} from '../../../../api/region';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

export function EditRegionsPage() {
	const [region, setRegion] = useState({ name: '' });
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const regionId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!regionId) {
			disableLoadingData();
			return;
		}
		getRegionById(regionId)
			.then((res) => {
				if (res.status === 200) {
					setRegion(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get region.' });
				history.push('/regions');
			});
	}, [regionId, disableLoadingData, history]);

	function saveRegion() {
		if (!regionId) {
			postRegion(region)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Guardada!',
							customMessage: 'Región creada con éxito.',
						});
						history.push('/regions');
					}
				})
				.catch((error) =>
					alertError({ error: error, customMessage: 'Could not save region.' })
				);
		} else {
			updateRegion(regionId, region)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Cambios guardados con éxito.',
						});
						history.push('/regions');
					}
				})
				.catch((error) =>
					alertError({ error: error, customMessage: 'Could not save changes.' })
				);
		}
	}

	const handleChange = (element) => (event) => {
		setRegion({ ...region, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar Región' />
					<CardBody>
						<TextField
							id={`name`}
							label='Nombre'
							value={region.name}
							onChange={handleChange('name')}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
							required
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/regions')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveRegion()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar región
				</Button>
				{regionId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								onClick={() => setOpenConfirmDialog(true)}
								variant='outlined'
								color='secondary'>
								Eliminar región
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={'¿Estás seguro de que quieres eliminar esta región?'}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteRegion(regionId)
									.then((res) => {
										if (res.status === 204 || res.status === 200) {
											alertSuccess({
												title: '¡Eliminada!',
												customMessage: 'Región eliminada con éxito',
											});
											history.push('/regions');
										}
									})
									.catch((error) =>
										alertError({
											error: error,
											customMessage: 'Could not delete region.',
										})
									);
							}}
						/>
					</>
				)}
			</>
		);
}
