import { uploadSingleFile } from "../files";
import { authClient, API } from "../index";

export const postNotification = async (notification, image, file) => {
	if (image) {
		const response = await uploadSingleFile(image, "notifications");
		notification.imageURL = response.data.fileURL;
	}
	if (file) {
		const response = await uploadSingleFile(file, "notifications");
		notification.fileURL = response.data.fileURL;
	}
	return authClient().post(`${API}/notification`, notification);
};

export const sendNotification = (notificationId) => {
	return authClient().post(`${API}/notification/${notificationId}/send`);
};

export const getNotifications = (query) => {
	return authClient().get(`${API}/notification${query}`);
};

export const getNotificationById = (id) => {
	return authClient().get(`${API}/notification/${id}`);
};

export const updateNotification = async (id, notification, image, file) => {
	if (image) {
		const response = await uploadSingleFile(image, "notifications");
		notification.imageURL = response.data.fileURL;
	}
	if (file) {
		const response = await uploadSingleFile(file, "notifications");
		notification.fileURL = response.data.fileURL;
	}
	return authClient().put(`${API}/notification/${id}`, notification);
};

export const changeStatusNotification = async (id, active) => {
	return authClient().put(`${API}/notification/change-status/${id}`, {
		active,
	});
};

export const deleteNotification = (id) => {
	return authClient().delete(`${API}/notification/${id}`);
};
