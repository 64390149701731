import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
	Select,
	FormHelperText,
	MenuItem,
	FormControl,
	InputLabel,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { deleteUser, getUserById, postUser, updateUser } from '../../../../api/user';
import { getRegions } from '../../../../api/region';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

const MenuProps = {
	PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
	getContentAnchorEl: () => null,
};

function getEmptyAdmin() {
	return {
		fullName: '',
		email: '',
		role: 'admin',
		password: '',
		repeatPassword: '',
		active: true,
		region: null,
	};
}

const getDataSelect = (elements) =>
	elements.map(({ _id, name }) => ({ value: _id, label: name }));

export function EditAdminsPage() {
	const [admin, setAdmin] = useState(getEmptyAdmin());
	const [regions, setRegions] = useState([]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [newPassword, setNewPassword] = useState({
		password: null,
		repeatPassword: null,
	});
	const [changePassword, setChangePassword] = useState(false);
	const adminId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	async function fetchRegions() {
		getRegions()
			.then((res) => {
				if (res.status === 200) setRegions(getDataSelect(res.data));
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener las regiones.',
				});
				history.push('/admins');
			});
	}

	useEffect(() => {
		if (!adminId) {
			disableLoadingData();
			fetchRegions();
			return;
		}
		getUserById(adminId)
			.then((res) => {
				if (res.status === 200) {
					const user = res.data;
					delete user.password;
					setAdmin(user);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se ha podido obtener al administrador.',
				});
				history.push('/admins');
			});
		fetchRegions();
	}, [adminId, disableLoadingData, history]);

	function saveAdmin() {
		let saveAdmin = admin;
		if (!adminId || changePassword) {
			if (!newPassword.password || !newPassword.repeatPassword) {
				alertError({
					error: null,
					customMessage: 'Introduce una contraseña.',
				});
				return;
			}
			if (newPassword.password !== newPassword.repeatPassword) {
				alertError({
					error: null,
					customMessage: 'Las contraseñas no coinciden.',
				});
				return;
			}
			saveAdmin = { ...saveAdmin, password: newPassword.password };
		}
		if (!adminId)
			postUser(saveAdmin)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Creado!',
							customMessage: 'Administrador creado con éxito.',
						});
						history.push('/admins');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'No se ha podido crear el administrador.',
					})
				);
		else
			updateUser(adminId, saveAdmin)
				.then((res) => {
					if (res.status === 200)
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Cambios guardados con éxito.',
						});
					history.push('/admins');
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					})
				);
	}

	const handleChange = (element) => (event) =>
		setAdmin({ ...admin, [element]: event.target.value });

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar administrador' />
					<CardBody>
						<TextField
							id={`fullName`}
							label='Nombre'
							value={admin.fullName}
							onChange={handleChange('fullName')}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`email`}
							label='Email'
							value={admin.email}
							onChange={handleChange('email')}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
							required
						/>
						{!adminId || changePassword ? (
							<>
								<br />
								<br />
								<TextField
									id={`password`}
									label='Contraseña'
									value={newPassword.password}
									onChange={(event) => {
										if (event.target.value !== ' ')
											setNewPassword({
												...newPassword,
												password: event.target.value,
											});
									}}
									InputLabelProps={{
										shrink: true,
									}}
									type='password'
									margin='normal'
									variant='outlined'
									required
								/>
								<TextField
									id={`repeatPassword`}
									label='Confirmar contraseña'
									value={newPassword.repeatPassword}
									onChange={(event) => {
										if (event.target.value !== ' ')
											setNewPassword({
												...newPassword,
												repeatPassword: event.target.value,
											});
									}}
									InputLabelProps={{ shrink: true }}
									type='password'
									margin='normal'
									variant='outlined'
									required
								/>
								<br />
								<br />
								{adminId && (
									<>
										<Button
											onClick={() => {
												setChangePassword(false);
												setNewPassword({
													password: null,
													repeatPassword: null,
												});
											}}
											variant='outlined'
											style={{ marginRight: '20px' }}>
											Cancelar cambio de contraseña
										</Button>
										<br />
										<br />
									</>
								)}
							</>
						) : (
							<>
								<br />
								<br />
								<Button
									onClick={() => setChangePassword(true)}
									variant='outlined'
									color='primary'
									style={{ marginRight: '20px' }}>
									Cambiar contraseña
								</Button>
								<br />
								<br />
							</>
						)}
						<br />
						<FormControl style={{ width: '100%' }} className='mb-2'>
							<InputLabel id='demo-simple-select-standard-label'>
								{'Región *'}
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={admin.region || ''}
								onChange={handleChange('region')}
								MenuProps={MenuProps}>
								{regions?.map(({ value, label }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{
									'La región del usuario determina los contenidos que este tiene asignados.'
								}
							</FormHelperText>
						</FormControl>
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={admin.active}
									onChange={() =>
										setAdmin({ ...admin, active: !admin.active })
									}
									name='checkActive'
								/>
							}
							label='Activo'
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/admins')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveAdmin()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar administrador
				</Button>
				{adminId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								onClick={() => setOpenConfirmDialog(true)}
								variant='outlined'
								color='secondary'>
								Eliminar administrador
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={
								'¿Estás seguro de que quieres eliminar este administrador?'
							}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() =>
								deleteUser(adminId)
									.then((res) => {
										if (
											res.status === 204 ||
											res.status === 200
										) {
											alertSuccess({
												title: '¡Eliminado!',
												customMessage:
													'Administrador eliminado con éxito.',
											});
											history.push('/admins');
										}
									})
									.catch((error) =>
										alertError({
											error: error,
											customMessage:
												'No se ha podido eliminar el administrador.',
										})
									)
							}
						/>
					</>
				)}
			</>
		);
}
