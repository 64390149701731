import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";

import EditEventsPage from "./modules/ContentManager/event/EditEventsPage";
import EventsPage from "./modules/ContentManager/event/EventsPage";
import EditEventTypesPage from "./modules/ContentManager/eventTypes/EditEventTypesPage";
import EventTypesPage from "./modules/ContentManager/eventTypes/EventTypesPage";
import EditLanguagesPage from "./modules/ContentManager/languages/EditLanguagesPage";
import LanguagesPage from "./modules/ContentManager/languages/LanguagesPage";
import EditResourcesPage from "./modules/ContentManager/resource/EditResourcesPage";
import ResourcesPage from "./modules/ContentManager/resource/ResourcesPage";
import EditResourceTypologiesPage from "./modules/ContentManager/resourceTypologies/EditResourceTypologiesPage";
import ResourceTypologiesPage from "./modules/ContentManager/resourceTypologies/ResourceTypologiesPage";
import EditTextsPage from "./modules/ContentManager/texts/EditTextsPage";
import TextsPage from "./modules/ContentManager/texts/TextsPage";
import AddThreadsPage from "./modules/ContentManager/threads/AddThreadsPage";
import ThreadsPage from "./modules/ContentManager/threads/ThreadsPage";
import ViewThreadsPage from "./modules/ContentManager/threads/ViewThreadsPage";
import { DashboardPage } from "./pages/DashboardPage";
import NotificationsPage from "./modules/ContentManager/notifications/NotificationsPage";
import EditNotificationsPage from "./modules/ContentManager/notifications/EditNotificationsPage";
import { TopicsPage, EditTopicsPage } from "./modules/ContentManager/topics";
import { AdminsPage, EditAdminsPage } from "./modules/ContentManager/admins";
import {
  FamiliesPage,
  EditFamiliesPage,
} from "./modules/ContentManager/families";
import { RegionsPage, EditRegionsPage } from "./modules/ContentManager/regions";
import { StoriesPage, EditStoriesPage } from "./modules/ContentManager/stories";
import {
  SurveysPage,
  EditSurveysPage,
  ViewSurveyPage,
} from "./modules/ContentManager/surveys";
import { SurveyResponsesPage } from "./modules/ContentManager/surveys/SurveyResponsesPage";
import {
  QuestionsPage,
  EditQuestionsPage,
} from "./modules/ContentManager/questions";
import {
  CategoriesPage,
  EditCategoriesPage,
} from "./modules/ContentManager/categories";
import {
  EnterprisesPages,
  EditEnterprisePage,
} from "./modules/ContentManager/enterprises";
import { ReportPage } from "./modules/ContentManager/report";

export default function BasePage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const isAdmin = user || user?.role !== "admin";

  return (
    <Switch>
      {/* Redirect from root URL to /dashboard. */}
      <Redirect exact from="/" to="/dashboard" />
      <ContentRoute path="/dashboard" component={DashboardPage} />
      {/* USERS */}
      {/* Administrators */}
      <ContentRoute from="/admins" component={AdminsPage} />
      <ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
      {/* Families */}
      <ContentRoute from="/families" component={FamiliesPage} />
      <ContentRoute from="/edit-family/:id?" component={EditFamiliesPage} />
      {/* Enterprises */}
      <ContentRoute from="/enterprises" component={EnterprisesPages} />
      <ContentRoute
        from="/edit-enterprise/:id?"
        component={EditEnterprisePage}
      />
      <ContentRoute from="/report" component={ReportPage} />
      {/* RESOURCES */}
      {/* Resources */}
      <ContentRoute from="/resources" component={ResourcesPage} />
      <ContentRoute from="/edit-resource/:id?" component={EditResourcesPage} />
      {/* Resource typologies */}
      <ContentRoute
        from="/resource-typologies"
        component={ResourceTypologiesPage}
      />
      <ContentRoute
        from="/edit-resource-typology/:id?"
        component={EditResourceTypologiesPage}
      />
      {/* Talleres */}
      {/* Eventos */}
      <ContentRoute from="/events" component={EventsPage} />
      <ContentRoute from="/edit-event/:id?" component={EditEventsPage} />
      {/* Tipos de evento */}
      <ContentRoute from="/event-types" component={EventTypesPage} />
      <ContentRoute
        from="/edit-event-type/:id?"
        component={EditEventTypesPage}
      />
      {/* Surveys */}
      {/* Surveys */}
      <ContentRoute from="/surveys" component={SurveysPage} />
      <ContentRoute from="/edit-survey/:id?" component={EditSurveysPage} />
      <ContentRoute from="/view-survey/:id?" component={ViewSurveyPage} />
      <ContentRoute
        from="/survey-responses/:id?"
        component={SurveyResponsesPage}
      />
      {/* Categories */}
      <ContentRoute from="/categories" component={CategoriesPage} />
      <ContentRoute from="/edit-category/:id?" component={EditCategoriesPage} />
      {/* Questions */}
      <ContentRoute from="/questions" component={QuestionsPage} />
      <ContentRoute from="/edit-question/:id?" component={EditQuestionsPage} />
      {/* Noticias */}
      {/* Stories */}
      <ContentRoute from="/stories" component={StoriesPage} />
      <ContentRoute from="/edit-story/:id?" component={EditStoriesPage} />
      {/* Intercambios */}
      {/* Foros */}
      <ContentRoute from="/threads" component={ThreadsPage} />
      <ContentRoute from="/add-thread" component={AddThreadsPage} />
      <ContentRoute from="/view-thread/:id" component={ViewThreadsPage} />
      {/* Tópicos */}
      <ContentRoute from="/topics" component={TopicsPage} />
      <ContentRoute from="/edit-topic/:id?" component={EditTopicsPage} />
      {/* Notificaciones */}
      {/* Notificaciones */}
      <ContentRoute from="/notifications" component={NotificationsPage} />
      <ContentRoute
        from="/edit-notification/:id?"
        component={EditNotificationsPage}
      />
      {/* MISCELLANY */}
      {/* Languages */}
      <ContentRoute
        from="/languages"
        component={!isAdmin ? DashboardPage : LanguagesPage}
      />
      <ContentRoute
        from="/edit-language/:id?"
        component={!isAdmin ? DashboardPage : EditLanguagesPage}
      />
      {/* Regions */}
      <ContentRoute
        from="/regions"
        component={!isAdmin ? DashboardPage : RegionsPage}
      />
      <ContentRoute
        from="/edit-region/:id?"
        component={!isAdmin ? DashboardPage : EditRegionsPage}
      />
      {/* Texts */}
      <ContentRoute
        from="/texts"
        component={!isAdmin ? DashboardPage : TextsPage}
      />
      <ContentRoute
        from="/edit-text/:id"
        component={!isAdmin ? DashboardPage : EditTextsPage}
      />
      <Redirect to="/error" />
    </Switch>
  );
}
