import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { Button, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { People } from '@material-ui/icons';

import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {
	changeStatusEvent,
	deleteEvent,
	getEvents,
} from '../../../../api/event';
import { getLabel, getNonEmpty } from '../../../../utils/helpers';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import Table, {
	booleanFormatter,
	buttonsStyle,
	dateFormatter,
} from '../../../components/tables/table';
import EventInfoDialog from '../../../components/dialogs/EventInfoDialog';
import { getEventTypes } from '../../../../api/eventType';
import { getRegions } from '../../../../api/region';
import FiltersCard from '../../../components/filters/Filter';
import { getEventParticipationByEvent } from '../../../../api/eventParticipation';
import TableDialog from '../../../components/dialogs/TableDialog';

function getListData(events, regions) {
	let data = [];
	for (let i = 0; i < events.length; ++i) {
		const event = events[i];
		const elem = {};
		elem.title = getNonEmpty(event.title);
		elem.eventTypeTitle = getNonEmpty(event.eventType?.title);
		elem.address = event.address;
		elem.region = getLabel(event.region, regions, '_id', 'name');
		elem.date = event.date;
		elem.inscriptionRequired = event.inscriptionRequired;
		elem.onLine = event.onLine;
		elem.createdAt = event.createdAt;
		elem.active = event.active;
		elem.id = event._id;
		data = data.concat(elem);
	}
	return data;
}

function getCalendarData(events, regions) {
	let data = [];
	for (let i = 0; i < events.length; ++i) {
		if (events[i].active) {
			const event = events[i];
			const elem = {};
			elem.title = getNonEmpty(event.title);
			elem.eventTypeTitle = getNonEmpty(event.eventType?.title);
			elem.address = event.address;
			elem.region = getLabel(event.region, regions, '_id', 'name');
			elem.date = event.date;
			elem.inscriptionRequired = event.inscriptionRequired;
			elem.onLine = event.onLine;
			elem.createdAt = event.createdAt;
			elem.active = event.active;
			elem.id = event._id;
			data = data.concat(elem);
		}
	}
	return data;
}

const initialFilters = {
	eventType: [],
};

export default function EventsPage() {
	const [data, setData] = useState([]);
	const [displayData, setDisplayData] = useState([]);
	const [eventTypes, setEventTypes] = useState([]);
	const [regions, setRegions] = useState([]);

	const [selectedEvent, setSelectedEvent] = useState(null);
	const [infoEventId, setInfoEventId] = useState(null);
	const [openInfoDialog, setOpenInfoDialog] = useState(null);

	const [eventParticipants, setEventParticipants] = useState([]);
	const [openUserTableDialog, setOpenUserTableDialog] = useState(null);

	const [filteredData, setFilteredData] = useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [filterOptions, setFilterOptions] = useState(initialFilters);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [listView, setListView] = useState(true);

	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-event/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && (
					<>
						<Tooltip title='Ver participantes'>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() =>
									getEventParticipationByEvent(cell)
										.then((res) => {
											if (res.status === 200) {
												let participants = res.data?.map((x) => x.user);
												setEventParticipants(participants);
												setSelectedEvent(elem);
												setOpenUserTableDialog(true);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													'No se pudieron obtener los participantes del evento.',
											});
										})
								}>
								<People />
							</Button>
						</Tooltip>
						<Tooltip title={elem?.active ? 'Disable' : 'Enable'}>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setSelectedEvent(elem);
									setOpenConfirmDialog(1);
								}}>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: 'red' }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title='Delete'>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setSelectedEvent(cell);
									setOpenConfirmDialog(2);
								}}>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: 'title', text: 'Título', sort: true },
		{ dataField: 'eventTypeTitle', text: 'Tipo', sort: true },
		{ dataField: 'address', text: 'Ubicación', sort: true },
		{ dataField: 'region', text: 'Región', sort: true },
		{
			dataField: 'date',
			text: 'Fecha',
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: 'inscriptionRequired',
			text: 'Requiere inscripción',
			headerAlign: 'center',
			align: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{
			dataField: 'onLine',
			text: 'Online',
			headerAlign: 'center',
			align: 'center',
			formatter: booleanFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getEventTypes()
			.then((res) => {
				if (res.status === 200) setEventTypes(res.data);
			})
			.catch((error) =>
				alertError({
					error: error,
					customMessage: 'No se pudieron obtener los tipos de eventos.',
				})
			);
		getEvents('?getDisabled=true')
			.then((res) => {
				if (res.status === 200) {
					let data = res.data;
					setData(data);
					setFilteredData(data);
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get events.' })
			);
		getRegions()
			.then((res) => {
				if (res.status === 200) {
					setRegions(res.data);
					setRefresh(false);
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get events.' })
			);
	}, [refresh]);

	useEffect(() => {
		if (!data.length) return;
		setDisplayData(
			listView
				? getListData(filteredData, regions)
				: getCalendarData(filteredData, regions)
		);
	}, [listView, filteredData, regions]);

	const handleEventClick = (clickInfo) => {
		setInfoEventId(clickInfo.event.id);
		setOpenInfoDialog(true);
	};

	const renderFiltersContent = () => {
		return (
			<>
				<Autocomplete
					multiple
					id='eventTypeFilter'
					filterSelectedOptions
					disablePortal
					options={eventTypes}
					getOptionLabel={(option) => getNonEmpty(option.title)}
					value={filterOptions.eventType || []}
					onChange={(event, selected) => {
						setFilterOptions({
							...filterOptions,
							eventType: selected,
						});
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							margin='normal'
							variant='outlined'
							InputLabelProps={{
								shrink: true,
							}}
							label='Tipo de evento'
						/>
					)}
				/>
				<br />
			</>
		);
	};

	const handleClearFilters = () => {
		setFilterOptions(initialFilters);
		setRefresh(true);
	};

	const handleSearch = async () => {
		if (!data.length) return;
		setFilteredData(
			data.filter((item) => {
				let filter = true;
				if (filterOptions.eventType && filterOptions.eventType.length)
					filter =
						filter &&
						filterOptions.eventType
							.map((x) => x._id)
							.includes(item.eventType?._id);

				if (filter) return item;
				return false;
			})
		);
	};

	return (
		<>
			<Card>
				<CardHeader
					title={listView ? 'Listado de eventos' : 'Calendario de eventos'}>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary mr-2'
							onClick={() => history.push('/edit-event')}>
							Añadir
						</button>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => setListView(!listView)}>
							{listView ? 'Calendario' : 'Listado'}
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>
					{listView ? (
						<Table data={displayData} columns={columns} />
					) : (
						<FullCalendar
							plugins={[
								dayGridPlugin,
								timeGridPlugin,
								interactionPlugin,
								listPlugin,
							]}
							headerToolbar={{
								left: 'prevYear,prev,next,nextYear,today',
								center: 'title',
								right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
							}}
							initialView='dayGridMonth'
							eventColor='#ffffff'
							weekends={true}
							events={displayData}
							eventClick={handleEventClick}
							locale={esLocale}
						/>
					)}
					<ConfirmDialog
						title={`Are you sure you want to ${
							selectedEvent?.active ? 'disable' : 'enable'
						} this event?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusEvent(selectedEvent._id, !selectedEvent?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedEvent?.active ? 'Disabled!' : 'Enabled!'
											}`,
											customMessage: `Event ${
												selectedEvent?.active ? 'disabled' : 'enabled'
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											selectedEvent?.active ? 'disable' : 'enable'
										} event.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={'Are you sure you want to remove this event?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteEvent(selectedEvent)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Event removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove event.',
									});
								});
						}}
					/>
					<EventInfoDialog
						open={openInfoDialog}
						setOpen={setOpenInfoDialog}
						workshopEvent={data.find((item) => item._id === infoEventId)}
						region={getLabel(
							data.find((item) => item._id === infoEventId)?.region,
							regions,
							'_id',
							'name'
						)}
						onConfirm={() => history.push('/edit-event/' + infoEventId)}
					/>
					<TableDialog
						title={`Participantes al evento "${getNonEmpty(
							selectedEvent?.title
						)}"`}
						open={openUserTableDialog}
						setOpen={setOpenUserTableDialog}
						userTable={true}
						data={eventParticipants}
					/>
				</CardBody>
			</Card>
		</>
	);
}
