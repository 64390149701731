import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, { buttonsStyle } from '../../../components/tables/table';
import { getRegions } from '../../../../api/region';
import { Button, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { alertError } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';

function getData(regions) {
	let data = [];
	for (let i = 0; i < regions.length; ++i) {
		const elem = {};
		elem.name = regions[i].name;
		elem.id = regions[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export function RegionsPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-region/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Nombre', sort: true },
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getRegions()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get regions.' })
			);
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de regiones'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-region')}>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
