import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
	changeStatusResourceTypology,
	deleteResourceTypology,
	getResourceTypologies,
} from "../../../../api/resourceTypology";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	imageFormatter,
} from "../../../components/tables/table";
import { changeStatusEventType, deleteEventType, getEventTypes } from "../../../../api/eventType";

function getData(resourceTypologies) {
	let data = [];
	for (let i = 0; i < resourceTypologies.length; ++i) {
		const elem = {};

		elem.title = getNonEmpty(resourceTypologies[i].title);
		elem.iconURL = resourceTypologies[i].iconURL;
		elem.createdAt = resourceTypologies[i].createdAt;
		elem.active = resourceTypologies[i].active;
		elem.id = resourceTypologies[i]._id;

		data = data.concat(elem);
	}
	return data;
}

export default function EventTypesPage() {
	const [data, setData] = useState([]);
	const [selectedEventType, setSelectedEventType] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-event-type/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === "admin" && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedEventType(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedEventType(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "iconURL",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: imageFormatter,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getEventTypes("?getDisabled=true")
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						"No se pudieron obtener los tipos de eventos.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Listado de tipos de eventos">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-event-type")}
						>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							selectedEventType?.active ? "disable" : "enable"
						} this resource typology?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusEventType(
								selectedEventType.id,
								!selectedEventType?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedEventType?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Resource typology ${
												selectedEventType?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											selectedEventType?.active
												? "disable"
												: "enable"
										} resource typology.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							"Are you sure you want to remove this resource typology?"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteEventType(selectedEventType)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Resource typology removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not remove resource typology.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
