import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
	Select,
	FormHelperText,
	MenuItem,
	FormControl,
	InputLabel,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import { deleteUser, getUserById, postUser, updateUser } from '../../../../api/user';
import { getRegions } from '../../../../api/region';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { useSkeleton } from '../../../hooks/useSkeleton';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

const MenuProps = {
	PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
	getContentAnchorEl: () => null,
};

function getEmptyFamily() {
	return {
		fullName: '',
		email: '',
		role: 'user',
		password: '',
		repeatPassword: '',
		active: true,
		region: null,
	};
}

const getDataSelect = (elements) =>
	elements.map(({ _id, name }) => ({ value: _id, label: name }));

export function EditFamiliesPage() {
	const [family, setFamily] = useState(getEmptyFamily());
	const [regions, setRegions] = useState([]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [newPassword, setNewPassword] = useState({
		password: null,
		repeatPassword: null,
	});
	const [changePassword, setChangePassword] = useState(false);
	const familyId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	async function fetchRegions() {
		getRegions()
			.then((res) => {
				if (res.status === 200) setRegions(getDataSelect(res.data));
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener las regiones.',
				});
				history.push('/families');
			});
	}

	useEffect(() => {
		if (!familyId) {
			disableLoadingData();
			fetchRegions();
			return;
		}
		getUserById(familyId)
			.then((res) => {
				if (res.status === 200) {
					const user = res.data;
					delete user.password;
					setFamily(user);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se ha podido obtener la família.',
				});
				history.push('/families');
			});
		fetchRegions();
	}, [familyId, disableLoadingData, history]);

	function saveFamily() {
		let saveFamily = family;
		delete saveFamily.password;

		if (changePassword) {
			if (!newPassword.password || !newPassword.repeatPassword) {
				alertError({
					error: null,
					customMessage: 'Introduce una contraseña.',
				});
				return;
			}
			if (newPassword.password !== newPassword.repeatPassword) {
				alertError({
					error: null,
					customMessage: 'Las contraseñas no coinciden.',
				});
				return;
			}
			saveFamily = { ...saveFamily, password: newPassword.password };
		}
		if (!familyId)
			postUser(saveFamily)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Creada!',
							customMessage: 'Família creada con éxito.',
						});
						history.push('/families');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'No se ha podido crear la família.',
					})
				);
		else
			updateUser(familyId, saveFamily)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: '¡Guardada!',
							customMessage: 'Cambios guardados con éxito.',
						});
						history.push('/families');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'No se han podido guardar los cambios.',
					})
				);
	}

	const handleChange = (element) => (event) =>
		setFamily({ ...family, [element]: event.target.value });

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar família' />
					<CardBody>
						<TextField
							id={`fullName`}
							label='Nombre'
							value={family.fullName}
							onChange={handleChange('fullName')}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`email`}
							label='Email'
							value={family.email}
							onChange={handleChange('email')}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
							required
						/>
						{changePassword ? (
							<>
								<br />
								<br />
								<TextField
									id={`password`}
									label='Contraseña'
									value={newPassword.password}
									onChange={(event) => {
										if (event.target.value !== ' ')
											setNewPassword({
												...newPassword,
												password: event.target.value,
											});
									}}
									InputLabelProps={{ shrink: true }}
									type='password'
									margin='normal'
									variant='outlined'
									required
								/>
								<TextField
									id={`repeatPassword`}
									label='Confirmar contraseña'
									value={newPassword.repeatPassword}
									onChange={(event) => {
										if (event.target.value !== ' ')
											setNewPassword({
												...newPassword,
												repeatPassword: event.target.value,
											});
									}}
									InputLabelProps={{ shrink: true }}
									type='password'
									margin='normal'
									variant='outlined'
									required
								/>
								<br />
								<br />
								{familyId && (
									<>
										<Button
											onClick={() => {
												setChangePassword(false);
												setNewPassword({
													password: null,
													repeatPassword: null,
												});
											}}
											variant='outlined'
											style={{ marginRight: '20px' }}>
											Cancelar cambio de contraseña
										</Button>
										<br />
										<br />
									</>
								)}
							</>
						) : familyId ? (
							<>
								<br />
								<br />
								<Button
									onClick={() => setChangePassword(true)}
									variant='outlined'
									color='primary'
									style={{ marginRight: '20px' }}>
									Cambiar contraseña
								</Button>
								<br />
								<br />
							</>
						) : (
							<></>
						)}
						<br />
						<FormControl style={{ width: '100%' }} className='mb-2'>
							<InputLabel id='demo-simple-select-standard-label'>
								{'Región *'}
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={family.region || ''}
								onChange={handleChange('region')}
								MenuProps={MenuProps}>
								{regions?.map(({ value, label }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{
									'La región del usuario determina los contenidos que este tiene asignados.'
								}
							</FormHelperText>
						</FormControl>
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={family.active}
									onChange={() =>
										setFamily({
											...family,
											active: !family.active,
										})
									}
									name='checkActive'
								/>
							}
							label='Activa'
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/families')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveFamily()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar família
				</Button>
				{familyId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								onClick={() => setOpenConfirmDialog(true)}
								variant='outlined'
								color='secondary'>
								Eliminar família
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={
								'¿Estás seguro de que quieres eliminar esta família?'
							}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() =>
								deleteUser(familyId)
									.then((res) => {
										if (
											res.status === 204 ||
											res.status === 200
										) {
											alertSuccess({
												title: '¡Eliminada!',
												customMessage:
													'Família eliminado con éxito.',
											});
											history.push('/families');
										}
									})
									.catch((error) =>
										alertError({
											error: error,
											customMessage:
												'No se ha podido eliminar la família.',
										})
									)
							}
						/>
					</>
				)}
			</>
		);
}
