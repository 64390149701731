import {
	AssignmentInd,
	AccountBalance,
	Event,
	Forum,
	Group,
	PublicOutlined,
	FormatAlignCenter,
} from '@material-ui/icons';

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getEvents } from '../../api/event';
import { getResources } from '../../api/resource';
import { getThreads } from '../../api/thread';
import { countRegions } from '../../api/region';
import { countStories } from '../../api/story';
import { countSurveys } from '../../api/survey';
import { countUsersByRole } from '../../api/user';
import { alertError } from '../../utils/logger';

export function DashboardPage() {
	const [data, setData] = useState([]);
	const history = useHistory();

	useEffect(() => {
		async function fetchData() {
			try {
				setData({
					numAdmins: (await countUsersByRole('admin'))?.data,
					numFamilies: (await countUsersByRole('user'))?.data,
					numEvents: (await getEvents('?getDisabled=true'))?.data?.length,
					numResources: (await getResources('?getDisabled=true'))?.data?.length,
					numThreads: (await getThreads('?getDisabled=true'))?.data?.length,
					numRegions: (await countRegions())?.data,
					numStories: (await countStories())?.data,
					numSurveys: (await countSurveys())?.data,
				});
			} catch (err) {
				alertError({ error: err, customMessage: 'Could not get statistics.' });
			}
		}
		fetchData();
	}, []);

	return (
		<div className='row justify-content-center'>
			<div
				className='col-12 col-md-4 text-center my-2'
				role='button'
				onClick={() => history.push('/admins')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'>
							<AssignmentInd /> Administradores
						</h5>
						<div className='contentDash'>{data.numAdmins}</div>
					</div>
				</div>
			</div>
			<div
				className='col-12 col-md-4 text-center my-2'
				role='button'
				onClick={() => history.push('/families')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'>
							<Group /> Familias
						</h5>
						<div className='contentDash'>{data.numFamilies}</div>
					</div>
				</div>
			</div>
			<div
				className='col-12 col-md-4 text-center my-2'
				role='button'
				onClick={() => history.push('/events')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'>
							<Event /> Eventos
						</h5>
						<div className='contentDash'>{data.numEvents}</div>
					</div>
				</div>
			</div>
			<div
				className='col-12 col-md-4 text-center my-2'
				role='button'
				onClick={() => history.push('/resources')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'>
							<AccountBalance /> Recursos
						</h5>
						<div className='contentDash'>{data.numResources}</div>
					</div>
				</div>
			</div>
			<div
				className='col-12 col-md-4 text-center my-2'
				role='button'
				onClick={() => history.push('/stories')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'>
							<FormatAlignCenter /> Noticias
						</h5>
						<div className='contentDash'>{data.numStories}</div>
					</div>
				</div>
			</div>
			<div
				className='col-12 col-md-4 text-center my-2'
				role='button'
				onClick={() => history.push('/threads')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'>
							<Forum /> Hilos del foro
						</h5>
						<div className='contentDash'>{data.numThreads}</div>
					</div>
				</div>
			</div>
			<div
				className='col-12 col-md-4 text-center my-2'
				role='button'
				onClick={() => history.push('/surveys')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'>
							<Forum /> Encuestas
						</h5>
						<div className='contentDash'>{data.numSurveys}</div>
					</div>
				</div>
			</div>
			<div
				className='col-12 col-md-4 text-center my-2'
				role='button'
				onClick={() => history.push('/regions')}>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'>
							<PublicOutlined /> Regiones
						</h5>
						<div className='contentDash'>{data.numRegions}</div>
					</div>
				</div>
			</div>
		</div>
	);
}
