import {
	Button,
	FormControl,
	FormHelperText,
	Select,
	TextField,
	Tooltip,
	InputLabel,
	MenuItem,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import { postThread } from '../../../../api/thread';
import { getTopics } from '../../../../api/topic';
import { getRegions } from '../../../../api/region';
import { alertError, alertSuccess } from '../../../../utils/logger';
import TableDialog from '../../../components/dialogs/TableDialog';
import Editor from '../../../components/editor/Editor';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { getNonEmpty } from '../../../../utils/helpers';

const MenuProps = {
	PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
	getContentAnchorEl: () => null,
};

function getEmptyThread(defaultRegion = null) {
	return {
		title: '',
		text: '',
		topic: null,
		createdBy: null,
		validated: true,
		region: defaultRegion,
	};
}

const getTopicData = (topics) =>
	topics.map((x) => ({ ...x, title: getNonEmpty(x.title) }));

const getDataSelect = (elements) =>
	elements.map(({ _id, name }) => ({ value: _id, label: name }));

export default function AddThreadsPage() {
	const user = useSelector((store) => store.authentication?.user, shallowEqual);
	const history = useHistory();

	const [thread, setThread] = useState(getEmptyThread(user?.region));
	const [topics, setTopics] = useState(null);
	const [regions, setRegions] = useState([]);
	const [openTableDialog, setOpenTableDialog] = useState(null);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getTopics()
			.then((res) => {
				if (res.status === 200) {
					setTopics(getTopicData(res.data));
					getRegions()
						.then((res) => {
							if (res.status === 200) {
								setRegions(getDataSelect(res.data));
								disableLoadingData();
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage:
									'No se han podido obtener las regiones.',
							});
							history.push('/threads');
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get topics.',
				});
				history.push('/threads');
			});
	}, [disableLoadingData, history]);

	function saveThread() {
		postThread({ ...thread, createdBy: user._id })
			.then((res) => {
				if (res.status === 201) {
					alertSuccess({
						title: 'Saved!',
						customMessage: 'Thread successfully created.',
					});
					history.push('/threads');
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not save thread.',
				});
			});
	}

	const handleChange = (element) => (event) => {
		if (event.target.value === ' ') return;
		setThread({ ...thread, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value) => {
		if (value === ' ') return;
		setThread({ ...thread, [element]: value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Crear hilo de foro'></CardHeader>
					<CardBody>
						<TextField
							id={`title`}
							label='Title'
							value={thread.title}
							onChange={handleChange('title')}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							label='Tópico'
							value={
								topics.find((x) => x._id === thread?.topic)?.title
							}
							InputLabelProps={{ shrink: true }}
							required
							placeholder='Seleccionar tópico'
							margin='normal'
							variant='outlined'
							inputProps={{ readOnly: true }}
							InputProps={
								({ readOnly: true },
								{
									endAdornment: (
										<Tooltip title='Seleccionar tópico'>
											<Button
												onClick={() => {
													setOpenTableDialog(true);
												}}>
												<AddBox />
											</Button>
										</Tooltip>
									),
								})
							}
						/>
						<br />
						<br />
						<FormControl style={{ width: '100%' }} className='mb-2'>
							<InputLabel id='demo-simple-select-standard-label'>
								{'Región *'}
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={thread.region || ''}
								onChange={handleChange('region')}
								MenuProps={MenuProps}>
								{regions?.map(({ value, label }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{
									'La región del hilo determinará qué usuarios tienen acceso a él.'
								}
							</FormHelperText>
						</FormControl>
						<br />
						<br />
						<div>Texto *</div>
						<br />
						<Editor
							body={thread.text || ''}
							setBody={(new_body) =>
								handleChangeEditor('text', new_body)
							}
							className='max-height'
							placeholder={'Escribe el texto del mensaje aquí...'}
						/>
					</CardBody>
				</Card>
				<TableDialog
					title={'Tópicos'}
					open={openTableDialog}
					setOpen={setOpenTableDialog}
					data={topics}
					onSelectRow={(selectedRow) => {
						setThread({
							...thread,
							topic: selectedRow ? selectedRow?._id : null,
						});
						setOpenTableDialog(false);
					}}></TableDialog>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/threads')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Atrás
					</Button>
					<Button
						onClick={() => saveThread()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Guardar hilo
					</Button>
				</div>
			</>
		);
}
