import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, { buttonsStyle } from '../../../components/tables/table';
import { getQuestions } from '../../../../api/question';
import { getCategories } from '../../../../api/category';
import { Button, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { alertError } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import { getNonEmpty } from '../../../../utils/helpers';

const types = {
	test: 'Test',
	'test-multiple': 'Test múltiple',
	numeric: 'Numérica',
	boolean: 'Sí/No',
	open: 'Abierta',
};

const getData = (questions, categories) =>
	questions.map((questions) => ({
		id: questions._id,
		name: getNonEmpty(questions.name),
		type: types[questions.type],
		category: getNonEmpty(
			categories.find((category) => category._id === questions.category)?.name
		),
	}));

export function QuestionsPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<Tooltip title='Editar'>
				<Button
					style={buttonsStyle}
					size='small'
					onClick={() => history.push('/edit-question/' + cell)}>
					<EditIcon />
				</Button>
			</Tooltip>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Pregunta', sort: true },
		{
			dataField: 'category',
			text: 'Categoría',
			sort: true,
			align: 'center',
			headerAlign: 'center',
		},
		{
			dataField: 'type',
			text: 'Tipo de Pregunta',
			sort: true,
			align: 'center',
			headerAlign: 'center',
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getQuestions()
			.then((res) => {
				if (res.status === 200) {
					const questions = res.data;
					getCategories()
						.then((res) => {
							if (res.status === 200) {
								setData(getData(questions, res.data));
								setRefresh(false);
							}
						})
						.catch((error) =>
							alertError({
								error: error,
								customMessage: 'Could not get categories.',
							})
						);
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get questions.' })
			);
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de preguntas'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-question')}>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
