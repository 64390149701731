import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, { buttonsStyle } from '../../../components/tables/table';
import { getCategories } from '../../../../api/category';
import { alertError } from '../../../../utils/logger';
import { getNonEmpty } from '../../../../utils/helpers';

const getData = (categories) =>
	categories.map((category) => ({
		name: getNonEmpty(category.name),
		id: category._id,
	}));

export function CategoriesPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-category/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Nombre', sort: true },
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getCategories()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				console.log({ error });
				alertError({
					error: error,
					customMessage: 'Could not get categories.',
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Listado de categorías'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-category')}>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
