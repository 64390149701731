import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import Table from "../../../components/tables/table";
import { getSurveyReport } from "../../../../api/survey";
import { getColumns } from "./utils";

const types = {
  test: "Test",
  "test-multiple": "Test múltiple",
  numeric: "Numérica",
  boolean: "Sí/No",
  open: "Abierta",
};

export function ViewSurveyPage() {
  const surveyId = useParams().id;
  const history = useHistory();
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSurveyReport(surveyId)
      .then((res) => {
        if (!res.data?.entries) throw new Error("No data");
        setReport(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        history.push("/surveys");
      });
  }, []);

  if (loading)
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{
          position: "absolute",
          top: "50%",
          bottom: "50%",
          left: "50%",
        }}
      >
        <span className="mb-10 spinner spinner-primary" />
        <h2>Cargando informe</h2>
      </div>
    );
  else
    return report.data?.map(({ name, category, type, answers }, i) => {
      if (type === "test-multiple")
        console.log({ data: answers[0], columns: getColumns(answers[0]) });
      return (
        <Card key={i}>
          <CardHeader title={"Pregunta"} />
          <div key={i}>
            <CardBody>
              <CardBody className=" bg-gray-100 rounded-card">
                <div className="d-flex row justify-content-between">
                  <h5>{name}</h5>
                  <span>{category}</span>
                </div>
                <span>{types[type]}</span>
                {type === "test-multiple" ? (
                  <div>
                    {answers.map(({ name, entries }) => (
                      <>
                        <Table
                          title={name}
                          data={entries}
                          columns={getColumns(entries[0])}
                          keyField={"name"}
                          showSearch={false}
                        />
                      </>
                    ))}
                  </div>
                ) : (
                  <div className="d-flex flex-row">
                    <Table
                      data={answers}
                      columns={getColumns(answers[0])}
                      keyField={"name"}
                      showSearch={false}
                    />
                  </div>
                )}
              </CardBody>
            </CardBody>
          </div>
        </Card>
      );
    });
}
