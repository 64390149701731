import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core';
import { dateFormatter } from '../tables/table';
import { getNonEmpty } from '../../../utils/helpers';
import { GOOGLE_MAPS_API_KEY } from '../../../utils/constants';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';

const EventInfoDialog = (props) => {
	const { open, setOpen, workshopEvent, region, onConfirm } = props;

	const Map = compose(
		withProps({
			googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
			loadingElement: <div style={{ height: '100%' }} />,
			containerElement: <div style={{ height: '250px', width: '400px' }} />,
			mapElement: <div style={{ height: '100%' }} />,
		}),
		withScriptjs,
		withGoogleMap
	)((props) => (
		<GoogleMap
			defaultZoom={13}
			defaultCenter={{
				lat: parseFloat(workshopEvent?.latitude),
				lng: parseFloat(workshopEvent?.longitude),
			}}>
			<Marker
				position={{
					lat: parseFloat(workshopEvent?.latitude),
					lng: parseFloat(workshopEvent?.longitude),
				}}
			/>
		</GoogleMap>
	));
	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			aria-labelledby='confirm-dialog'
			className='backdrop'>
			<DialogTitle id='confirm-dialog'>
				{getNonEmpty(workshopEvent?.title) || 'Event details'}
			</DialogTitle>
			<DialogContent>
				<p>
					<strong>Description: </strong>
					{getNonEmpty(workshopEvent?.description)}
				</p>
				<p>
					<strong>Tipo: </strong>
					{getNonEmpty(workshopEvent?.eventType?.title)}
				</p>
				<p>
					<strong>Date: </strong>
					{dateFormatter(workshopEvent?.date) + 'h'}
				</p>
				<p>
					<strong>Modality: </strong>
					{workshopEvent?.onLine ? <>On Line</> : <>Presencial</>}
				</p>
				{!workshopEvent?.onLine && (
					<p>
						<strong>Location: </strong>
						{workshopEvent?.address}
					</p>
				)}
				<p>
					<strong>Región: </strong>
					{region}
				</p>
				<p>
					<strong>Inscription required: </strong>
					{workshopEvent?.inscriptionRequired ? <>Yes</> : <>No</>}
				</p>
				<div className='mt-3'>
					<Map></Map>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					className='mt-3'
					variant='outlined'
					color='primary'
					onClick={() => {
						setOpen(false);
						onConfirm();
					}}>
					View event
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EventInfoDialog;
