import { API, authClient } from "../index";

export const postResource = async (resource) => {
	return authClient().post(`${API}/resource`, resource);
};

export const getResources = (query) => {
	return authClient().get(`${API}/resource${query || ""}`);
};

export const getResourceById = (id) => {
	return authClient().get(`${API}/resource/${id}`);
};

export const updateResource = async (id, resource) => {
	return authClient().put(`${API}/resource/${id}`, resource);
};

export const changeStatusResource = async (id, validated) => {
	return authClient().put(`${API}/resource/change-status/${id}`, {
		validated,
	});
};

export const deleteResource = (id) => {
	return authClient().delete(`${API}/resource/${id}`);
};
