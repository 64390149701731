import { API, authClient } from "../index";

export const postThread = async (thread) => {
	return authClient().post(`${API}/thread`, thread);
};

export const getThreads = (query) => {
	return authClient().get(`${API}/thread${query || ""}`);
};

export const getThreadById = (id) => {
	return authClient().get(`${API}/thread/${id}`);
};

export const changeStatusThread = async (id, validated) => {
	return authClient().put(`${API}/thread/change-status/${id}`, {
		validated,
	});
};

export const deleteThread = (id) => {
	return authClient().delete(`${API}/thread/${id}`);
};
