import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import Table from '../../../components/tables/table';
import { getReport } from '../../../../api/report';
import { getColumns } from './utils';

export function ReportPage() {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getReport().then((res) => {
			setData(res.data);
			setLoading(false);
		});
	}, []);

	if (loading)
		return (
			<div
				className='d-flex flex-column justify-content-center align-items-center'
				style={{
					position: 'absolute',
					top: '50%',
					bottom: '50%',
					left: '50%',
				}}>
				<span className='mb-10 spinner spinner-primary' />
				<h2>Cargando informe</h2>
			</div>
		);
	else
		return Object.values(data).map(({ name, data, enabled }, i) => {
			if (!Array.isArray(data)) return;
			return (
				<Card key={i}>
					<CardHeader title={`Indicadores ${name}`} />
					{data?.map(({ type, reportData }, i) => {
						if (!enabled) return;
						return (
							<div key={i}>
								<CardBody>
									<CardBody className=' bg-gray-100 rounded-card'>
										<h5>{type.name}</h5>
										<span>{type.description}</span>
										<div className='d-flex flex-row'>
											<Table
												data={reportData}
												columns={getColumns(reportData[0])}
												keyField={'name'}
												showSearch={false}
											/>
										</div>
									</CardBody>
								</CardBody>
							</div>
						);
					})}
				</Card>
			);
		});
}
