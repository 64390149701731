import { uploadSingleFile } from "../files";
import { authClient, API } from "../index";

export const postResourceTypology = async (resourceTypology, icon) => {
	if (icon) {
		const response = await uploadSingleFile(icon, "resources");
		resourceTypology.iconURL = response.data.fileURL;
	}
	return authClient().post(`${API}/resource-typology`, resourceTypology);
};

export const getResourceTypologies = (query) => {
	return authClient().get(`${API}/resource-typology${query || ""}`);
};

export const getResourceTypologyById = (id) => {
	return authClient().get(`${API}/resource-typology/${id}`);
};

export const updateResourceTypology = async (id, resourceTypology, icon) => {
	if (icon) {
		const response = await uploadSingleFile(icon, "resources");
		resourceTypology.iconURL = response.data.fileURL;
	}
	return authClient().put(`${API}/resource-typology/${id}`, resourceTypology);
};

export const changeStatusResourceTypology = async (id, active) => {
	return authClient().put(`${API}/resource-typology/change-status/${id}`, {
		active,
	});
};

export const deleteResourceTypology = (id) => {
	return authClient().delete(`${API}/resource-typology/${id}`);
};
