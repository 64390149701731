import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
	Select,
	FormHelperText,
	MenuItem,
	FormControl,
	InputLabel,
} from '@material-ui/core';
import { AddBox, Map } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	deleteEvent,
	getEventById,
	postEvent,
	updateEvent,
} from '../../../../api/event';
import { GOOGLE_MAPS_API_KEY } from '../../../../utils/constants';
import { alertError, alertSuccess } from '../../../../utils/logger';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import MapDialog from '../../../components/dialogs/MapDialog';
import Editor from '../../../components/editor/Editor';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { getEventTypes } from '../../../../api/eventType';
import { getRegions } from '../../../../api/region';
import TableDialog from '../../../components/dialogs/TableDialog';
import { getNonEmpty, getLabel } from '../../../../utils/helpers';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

const MenuProps = {
	PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
	getContentAnchorEl: () => null,
};

function getEmptyWorkshopEvent() {
	return {
		title: {},
		description: {},
		eventType: null,
		latitude: null,
		longitude: null,
		address: null,
		date: null,
		region: null,
		inscriptionRequired: false,
		onLine: false,
		active: true,
	};
}

const getDataSelect = (elements) =>
	elements.map(({ _id, name }) => ({ value: _id, label: name }));

const getEventTypeData = (eventTypes) =>
	eventTypes.map((x) => ({ ...x, title: getNonEmpty(x.title) }));

export default function EditEventsPage() {
	const [workshopEvent, setWorkshopEvent] = useState(getEmptyWorkshopEvent());
	const [eventTypes, setEventTypes] = useState([]);
	const [regions, setRegions] = useState([]);

	const [openTableDialog, setOpenTableDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openMapDialog, setOpenMapDialog] = useState(false);

	const [selectedLocation, setSelectedLocation] = useState({
		latitude: null,
		longitude: null,
	});

	const eventId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getEventTypes()
			.then((res) => {
				if (res.status === 200) {
					setEventTypes(getEventTypeData(res.data));
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get event.' });
				history.push('/events');
			});
		getRegions()
			.then((resp) => {
				if (resp.status === 200) setRegions(getDataSelect(resp.data));
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get regions.',
				});
				history.push('/events');
			});
		if (!eventId) {
			disableLoadingData();
			return;
		}
		getEventById(eventId)
			.then((res) => {
				if (res.status === 200) {
					setWorkshopEvent({ ...res.data, date: new Date(res.data.date) });
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get event.' });
				history.push('/events');
			});
	}, [eventId, disableLoadingData, history]);

	function saveEvent() {
		if (!eventId)
			postEvent({ ...workshopEvent, createdBy: user._id })
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Event successfully created.',
						});
						history.push('/events');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save event.',
					})
				);
		else
			updateEvent(eventId, workshopEvent)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/events');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					})
				);
	}

	const handlePlaceSelect = (place) => {
		let latitude = place.geometry.location.lat();
		let longitude = place.geometry.location.lng();
		let address = place.formatted_address;

		setSelectedLocation({ latitude, longitude, address });
		setWorkshopEvent((prevWorkshopEvent) => ({
			...prevWorkshopEvent,
			latitude,
			longitude,
			address,
		}));
	};
	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!workshopEvent[element]) workshopEvent[element] = {};
			let newText = workshopEvent[element];
			newText[lang] = event.target.value;
			setWorkshopEvent({ ...workshopEvent, [element]: newText });
		} else setWorkshopEvent({ ...workshopEvent, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value === ' ') return;
		if (lang) {
			if (!workshopEvent[element]) workshopEvent[element] = {};
			let newText = workshopEvent[element];
			newText[lang] = value;
			setWorkshopEvent({ ...workshopEvent, [element]: newText });
		} else setWorkshopEvent({ ...workshopEvent, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label='Title'
					value={(workshopEvent.title && workshopEvent.title[lang]) || ''}
					onChange={handleChange('title', lang)}
					InputLabelProps={{ shrink: true }}
					margin='normal'
					variant='outlined'
					required
				/>
				<br />
				<br />
				<div>Descripción</div>
				<br />
				<Editor
					body={
						(workshopEvent.description &&
							workshopEvent.description[lang]) ||
						''
					}
					setBody={(new_body) =>
						handleChangeEditor('description', new_body, lang)
					}
					className='max-height'
					placeholder={'Enter event description here...'}
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit event' />
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={renderMultilanguageTabContent}
						/>
						<br />
						<TextField
							label='Tipo de evento'
							value={
								eventTypes.find(
									(x) => x._id === workshopEvent?.eventType
								)?.title
							}
							InputLabelProps={{ shrink: true }}
							required
							placeholder='Selecciona el tipo de evento'
							margin='normal'
							variant='outlined'
							inputProps={{ readOnly: true }}
							InputProps={
								({ readOnly: true },
								{
									endAdornment: (
										<Tooltip title='Seleccionar tipo de evento'>
											<Button
												onClick={() => {
													setOpenTableDialog(true);
												}}>
												<AddBox />
											</Button>
										</Tooltip>
									),
								})
							}
						/>
						<br />
						<br />
						<div>Fecha *</div>
						<div className='row d-flex align-items-center'>
							<div className='col-md-4'>
								<DatePicker
									className='pointer'
									locale='es'
									dateFormat='dd/MM/yyyy HH:mm'
									placeholderText='Date Time (Spain):'
									selected={workshopEvent?.date}
									popperPlacement='bottom-end'
									showTimeSelect
									timeIntervals={15}
									onChange={(date) => {
										setWorkshopEvent({
											...workshopEvent,
											date,
										});
									}}
									showMonthDropdown
									showYearDropdown
									todayButton='Today'
									minDate={new Date()}
								/>
							</div>
							<div className='col-md-4'>
								<FormControlLabel
									control={
										<Checkbox
											checked={
												workshopEvent.inscriptionRequired
											}
											onChange={() =>
												setWorkshopEvent({
													...workshopEvent,
													inscriptionRequired: !workshopEvent.inscriptionRequired,
												})
											}
											name='checkActive'
										/>
									}
									label='Requiere inscripción'
								/>
							</div>
							<div className='col-md-4'>
								<FormControlLabel
									control={
										<Checkbox
											checked={workshopEvent.onLine}
											onChange={() => {
												setWorkshopEvent({
													...workshopEvent,
													onLine: !workshopEvent.onLine,
													latitude: !workshopEvent.onLine
														? null
														: workshopEvent.latitude,
													longitude: !workshopEvent.onLine
														? null
														: workshopEvent.longitude,
													address: !workshopEvent.onLine
														? null
														: workshopEvent.address,
												});

												if (!workshopEvent.onLine)
													setSelectedLocation({
														latitude: null,
														longitude: null,
													});
											}}
											name='checkActive'
										/>
									}
									label='Online'
								/>
							</div>
						</div>
						{!workshopEvent?.onLine && (
							<>
								<br />
								<div>Ubicación *</div>
								<br />
								<div className='d-flex align-items-center location-container'>
									<ReactGoogleAutocomplete
										className='p-3 w-100'
										apiKey={GOOGLE_MAPS_API_KEY}
										options={{
											types: ['geocode', 'establishment'],
											componentRestrictions: {
												country: 'es',
											},
										}}
										onChange={(e) => {
											if (!e.target.value) {
												setWorkshopEvent({
													...workshopEvent,
													latitude: null,
													longitude: null,
													address: null,
												});
												setSelectedLocation({
													latitude: null,
													longitude: null,
												});
											}
										}}
										defaultValue={workshopEvent?.address}
										onPlaceSelected={handlePlaceSelect}
									/>
									{((selectedLocation.latitude &&
										selectedLocation.longitude) ||
										(workshopEvent.latitude &&
											workshopEvent.longitude)) && (
										<Tooltip title='Ver en mapa'>
											<Button
												onClick={() => {
													setOpenMapDialog(true);
												}}>
												<Map />
											</Button>
										</Tooltip>
									)}
								</div>
								<br />
							</>
						)}
						<br />
						<FormControl style={{ width: '100%' }} className='mb-2'>
							<InputLabel id='demo-simple-select-standard-label'>
								{'Región *'}
							</InputLabel>
							<Select
								labelId='demo-simple-select-standard-label'
								id='demo-simple-select-standard'
								value={workshopEvent.region || ''}
								onChange={handleChange('region')}
								MenuProps={MenuProps}>
								{regions?.map(({ value, label }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{`Solo los usuarios de ${
									workshopEvent.region
										? getLabel(workshopEvent.region, regions)
										: 'esta región'
								} tendrán acceso al evento`}
							</FormHelperText>
						</FormControl>
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={workshopEvent.active}
									onChange={() =>
										setWorkshopEvent({
											...workshopEvent,
											active: !workshopEvent.active,
										})
									}
									name='checkActive'
								/>
							}
							label='Active'
						/>
					</CardBody>
				</Card>
				<TableDialog
					title={'Tipos de evento'}
					open={openTableDialog}
					setOpen={setOpenTableDialog}
					userTable={false}
					data={eventTypes}
					onSelectRow={(selectedRow) => {
						setWorkshopEvent({
							...workshopEvent,
							eventType: selectedRow ? selectedRow?._id : null,
						});
						setOpenTableDialog(false);
					}}
				/>
				<MapDialog
					open={openMapDialog}
					setOpen={setOpenMapDialog}
					latitude={selectedLocation.latitude || workshopEvent.latitude}
					longitude={selectedLocation.longitude || workshopEvent.longitude}
				/>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/events')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveEvent()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save event
					</Button>
					{eventId && user?.role === 'admin' && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete event
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}
								/>
							</MuiThemeProvider>
							<ConfirmDialog
								title={'Are you sure you want to delete this event?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteEvent(eventId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: 'Deleted!',
													customMessage:
														'Event deleted successfully',
												});
												history.push('/events');
											}
										})
										.catch((error) =>
											alertError({
												error: error,
												customMessage:
													'Could not delete event.',
											})
										);
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
