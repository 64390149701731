import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteCategory,
	getCategoryById,
	postCategory,
	updateCategory,
} from '../../../../api/category';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

export function EditCategoriesPage() {
	const [category, setCategory] = useState({ name: {} });
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const categoryId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!categoryId) {
			disableLoadingData();
			return;
		}
		getCategoryById(categoryId)
			.then((res) => {
				if (res.status === 200) {
					setCategory(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get category.' });
				history.push('/categories');
			});
	}, [categoryId, disableLoadingData, history]);

	function saveCategory() {
		if (!categoryId)
			postCategory(category)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Guardada!',
							customMessage: 'Categoría creada con éxito.',
						});
						history.push('/categories');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save category.',
					})
				);
		else
			updateCategory(categoryId, category)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Cambios guardados con éxito.',
						});
						history.push('/categories');
					}
				})
				.catch((error) =>
					alertError({ error: error, customMessage: 'Could not save changes.' })
				);
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!category[element]) category[element] = {};
			let newCategory = category[element];
			newCategory[lang] = event.target.value;
			setCategory({ ...category, [element]: newCategory });
		} else setCategory({ ...category, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar categoría' />
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={(lang) => (
								<>
									<br />
									<TextField
										id={`name`}
										label='Nombre'
										value={(category.name && category.name[lang]) || ''}
										onChange={handleChange('name', lang)}
										InputLabelProps={{ shrink: true }}
										margin='normal'
										variant='outlined'
										required
									/>
								</>
							)}
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/categories')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveCategory()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar categoría
				</Button>
				{categoryId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								onClick={() => setOpenConfirmDialog(true)}
								variant='outlined'
								color='secondary'>
								Eliminar categoría
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={'¿Estás seguro de que quieres eliminar esta categoría?'}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteCategory(categoryId)
									.then((res) => {
										if (res.status === 204 || res.status === 200) {
											alertSuccess({
												title: '¡Eliminada!',
												customMessage: 'Categoría eliminada con éxito',
											});
											history.push('/categories');
										}
									})
									.catch((error) =>
										alertError({
											error: error,
											customMessage: 'Could not delete category.',
										})
									);
							}}
						/>
					</>
				)}
			</>
		);
}
