/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DescriptionIcon from '@material-ui/icons/Description';
import LanguageIcon from '@material-ui/icons/Language';
import CategoryIcon from '@material-ui/icons/Category';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import { shallowEqual, useSelector } from 'react-redux';
import {
	AccountBalance,
	Category,
	Event,
	Assessment,
	Forum,
	Domain,
	Group,
	Notifications,
	SpeakerNotes,
	PublicOutlined,
	FormatListBulleted,
	LiveHelp,
} from '@material-ui/icons';

export function AsideMenuList({ layoutProps }) {
	const user = useSelector((store) => store.authentication?.user, shallowEqual);
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: '';
	};

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/dashboard'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl(
									'/media/svg/icons/Design/Layers.svg'
								)}
							/>
						</span>
						<span className='menu-text'>Dashboard</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>Perfiles</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				{user.role === 'admin' && (
					<li
						className={`menu-item ${getMenuItemActive(
							'/admins',
							false
						)} ${getMenuItemActive('/edit-admin', false)}`}
						aria-haspopup='true'>
						<NavLink className='menu-link' to='/admins'>
							<span className='menu-icon'>
								<AssignmentIndIcon />
							</span>
							<span className='menu-text'>Administradores</span>
						</NavLink>
					</li>
				)}
				<li
					className={`menu-item ${getMenuItemActive(
						'/families',
						false
					)} ${getMenuItemActive('/edit-family', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/families'>
						<span className='menu-icon'>
							<Group />
						</span>
						<span className='menu-text'>Familias</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/enterprises',
						false
					)} ${getMenuItemActive('/edit-enterprise', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/enterprises'>
						<span className='menu-icon'>
							<Domain />
						</span>
						<span className='menu-text'>Empresas</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>Indicadores</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/report', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/report'>
						<span className='menu-icon'>
							<Assessment />
						</span>
						<span className='menu-text'>Informe</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>Talleres</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/events',
						false
					)} ${getMenuItemActive('/edit-event', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/events'>
						<span className='menu-icon'>
							<Event />
						</span>
						<span className='menu-text'>Eventos</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/event-types',
						false
					)} ${getMenuItemActive('/edit-event-type', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/event-types'>
						<span className='menu-icon'>
							<Category />
						</span>
						<span className='menu-text'>Tipos de eventos</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>RECURSOS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/resources',
						false
					)} ${getMenuItemActive('/edit-resource', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/resources'>
						<span className='menu-icon'>
							<AccountBalance />
						</span>
						<span className='menu-text'>Recursos</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/resource-typologies',
						false
					)} ${getMenuItemActive('/edit-resource-typology', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/resource-typologies'>
						<span className='menu-icon'>
							<CategoryIcon />
						</span>
						<span className='menu-text'>Tipologías de recursos</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>ENCUESTAS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/surveys',
						false
					)} ${getMenuItemActive('/edit-survey', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/surveys'>
						<span className='menu-icon'>
							<FormatListBulleted />
						</span>
						<span className='menu-text'>Encuestas</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/categories',
						false
					)} ${getMenuItemActive('/edit-category', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/categories'>
						<span className='menu-icon'>
							<CategoryIcon />
						</span>
						<span className='menu-text'>Categorías</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/questions',
						false
					)} ${getMenuItemActive('/edit-question', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/questions'>
						<span className='menu-icon'>
							<LiveHelp />
						</span>
						<span className='menu-text'>Preguntas</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>NOTICIAS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/stories',
						false
					)} ${getMenuItemActive('/edit-story', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/stories'>
						<span className='menu-icon'>
							<FormatAlignCenterIcon />
						</span>
						<span className='menu-text'>Noticias</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>INTERCAMBIOS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/threads',
						false
					)} ${getMenuItemActive('/view-thread', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/threads'>
						<span className='menu-icon'>
							<Forum />
						</span>
						<span className='menu-text'>Hilos del foro</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/topics',
						false
					)} ${getMenuItemActive('/edit-topic', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/topics'>
						<span className='menu-icon'>
							<SpeakerNotes />
						</span>
						<span className='menu-text'>Tópicos</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>ALERTAS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/notifications',
						false
					)} ${getMenuItemActive('/edit-notification', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/notifications'>
						<span className='menu-icon'>
							<Notifications />
						</span>
						<span className='menu-text'>Notificaciones</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>Miscelánea</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				{user.role === 'admin' && (
					<>
						<li
							className={`menu-item ${getMenuItemActive(
								'/languages',
								false
							)} ${getMenuItemActive('/edit-language', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/languages'>
								<span className='menu-icon'>
									<LanguageIcon />
								</span>
								<span className='menu-text'>Idiomas</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/regions',
								false
							)} ${getMenuItemActive('/edit-region', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/regions'>
								<span className='menu-icon'>
									<PublicOutlined />
								</span>
								<span className='menu-text'>Regions</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/texts',
								false
							)} ${getMenuItemActive('/edit-text', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/texts'>
								<span className='menu-icon'>
									<DescriptionIcon />
								</span>
								<span className='menu-text'>Textos</span>
							</NavLink>
						</li>
					</>
				)}
			</ul>

			{/* end::Menu Nav */}
		</>
	);
}
