import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Delete, Link, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteNotification,
	getNotificationById,
	postNotification,
	updateNotification,
} from "../../../../api/notification";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Editor from "../../../components/editor/Editor";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyNotification() {
	return {
		title: {},
		description: {},
		imageURL: null,
		fileURL: null,
		linkURL: null,
		active: false,
	};
}

export default function EditNotificationsPage() {
	const [notification, setNotification] = useState(getEmptyNotification());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);

	const [openPreviewImage, setOpenPreviewImage] = useState(false);
	const [openPreviewFile, setOpenPreviewFile] = useState(false);

	const notificationId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!notificationId) {
			disableLoadingData();
			return;
		}
		getNotificationById(notificationId)
			.then((res) => {
				if (res.status === 200) {
					setNotification(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get notification.",
				});
				history.push("/notifications");
			});
	}, [notificationId, disableLoadingData, history]);

	function saveNotification() {
		if (checkIsEmpty(notification.title))
			return alertError({
				error: null,
				customMessage: "El título es necesario en al menos un idioma",
			});
		if (!notificationId) {
			postNotification(notification, selectedImage, selectedFile)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "¡Guardada!",
							customMessage: "Notificación creada con éxito.",
						});
						history.push("/notifications");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save notification.",
					});
				});
		} else {
			updateNotification(
				notificationId,
				notification,
				selectedImage,
				selectedFile
			)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "¡Guardado!",
							customMessage: "Cambios guardados con éxito.",
						});
						history.push("/notifications");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save notifications.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!notification[element]) notification[element] = {};
			let newText = notification[element];
			newText[lang] = event.target.value;
			setNotification({ ...notification, [element]: newText });
		} else
			setNotification({
				...notification,
				[element]: event.target.value,
			});
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value === " ") return;
		if (lang) {
			if (!notification[element]) notification[element] = {};
			let newText = notification[element];
			newText[lang] = value;
			setNotification({ ...notification, [element]: newText });
		} else setNotification({ ...notification, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Título"
					value={
						(notification.title && notification.title[lang]) || ""
					}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<br />
				<div>Descripción</div>
				<br />
				<Editor
					body={
						(notification.description &&
							notification.description[lang]) ||
						""
					}
					setBody={(new_body) =>
						handleChangeEditor("description", new_body, lang)
					}
					className="max-height"
					placeholder={
						"Introduce la descripción de la notificación aquí..."
					}
				/>
				<br />
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Editar notificación"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<label htmlFor={"upload-image"}>
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedImage || notification.imageURL
									? "Cambiar imagen"
									: "Subir imagen"}
							</Button>
						</label>
						{(selectedImage || notification.imageURL) && (
							<>
								<Tooltip title={"Vista previa de imagen"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewImage(true)
										}
										style={{
											...buttonsStyle,
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<Tooltip title="Eliminar">
									<Button
										size="small"
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
										onClick={() => {
											setSelectedImage(null);
											setNotification({
												...notification,
												imageURL: null,
											});
										}}
									>
										<Delete />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Vista previa de imagen"}
									open={openPreviewImage}
									setOpen={setOpenPreviewImage}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${notification.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: notification.imageURL
										? notification.imageURL.split(
												/-(.*)/s
										  )[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<label htmlFor={"upload-file"}>
							<input
								style={{ display: "none" }}
								id={"upload-file"}
								name={"upload-file"}
								type="file"
								accept={"file/*"}
								onChange={(e) => {
									setSelectedFile(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedFile || notification.fileURL
									? "Cambiar archivo"
									: "Subir archivo"}
							</Button>
						</label>
						{(selectedFile || notification.fileURL) && (
							<>
								<Tooltip title={"Vista previa de archivo"}>
									<Button
										size="small"
										onClick={() => setOpenPreviewFile(true)}
										style={{
											...buttonsStyle,
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<Tooltip title="Eliminar">
									<Button
										size="small"
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
										onClick={() => {
											setSelectedFile(null);
											setNotification({
												...notification,
												fileURL: null,
											});
										}}
									>
										<Delete />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Vista previa de archivo"}
									open={openPreviewFile}
									setOpen={setOpenPreviewFile}
									src={
										selectedFile
											? URL.createObjectURL(selectedFile)
											: `${SERVER_URL}/${notification.fileURL}`
									}
								/>
								<span>
									{selectedFile
										? selectedFile?.name
										: notification.fileURL
										? notification.fileURL.split(
												/-(.*)/s
										  )[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<TextField
							id={`title`}
							label="Link"
							value={notification.linkURL || ""}
							onChange={handleChange("linkURL")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							InputProps={{
								endAdornment: notification?.linkURL ? (
									<Tooltip title="Ver enlace">
										<Button
											onClick={() => {
												window
													.open(
														notification.linkURL,
														"_blank"
													)
													.focus();
											}}
										>
											<Link />
										</Button>
									</Tooltip>
								) : null,
							}}
						/>
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={notification?.active}
									onChange={() =>
										setNotification({
											...notification,
											active: !notification?.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Activa"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/notifications")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Atrás
					</Button>
					<Button
						onClick={() => saveNotification()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Guardar notificación
					</Button>
					{notificationId && user?.role === "admin" && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Eliminar notificación
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"¿Estás seguro de que quieres eliminar esta notificación?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteNotification(notificationId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "¡Eliminada!",
													customMessage:
														"Notificación eliminada con éxito",
												});
												history.push("/notifications");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete notification.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
