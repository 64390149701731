import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Checkbox,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import {
	deleteQuestion,
	getQuestionById,
	postQuestion,
	updateQuestion,
} from '../../../../api/question';
import { getCategories } from '../../../../api/category';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getNonEmpty } from '../../../../utils/helpers';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

const getDataSelect = (elements) =>
	elements.map(({ _id, name }) => ({ value: _id, label: getNonEmpty(name) }));

const DefaultNumericLength = 5;

export function EditQuestionsPage() {
	const [question, setQuestion] = useState({
		name: {},
		category: null,
		answers: [],
		type: 'test',
	});
	const [categories, setCategories] = useState([]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const questionId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getCategories()
			.then((res) => {
				if (res.status === 200) setCategories(getDataSelect(res.data));
			})
			.catch((error) =>
				alertError({
					error: error,
					customMessage: 'Could not get categories.',
				})
			);
		if (!questionId) {
			disableLoadingData();
			return;
		}
		getQuestionById(questionId)
			.then((res) => {
				if (res.status === 200) {
					setQuestion(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get question.',
				});
				history.push('/questions');
			});
	}, [questionId, disableLoadingData, history]);

	function saveQuestion() {
		if (!questionId)
			postQuestion(question)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Guardada!',
							customMessage: 'Pregunta creada con éxito.',
						});
						history.push('/questions');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save question.',
					})
				);
		else
			updateQuestion(questionId, question)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Cambios guardados con éxito.',
						});
						history.push('/questions');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					})
				);
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!question[element]) question[element] = {};
			let newQuestion = question[element];
			newQuestion[lang] = event.target.value;
			setQuestion({ ...question, [element]: newQuestion });
		} else setQuestion({ ...question, [element]: event.value });
	};

	const handleChangeAnswer = (index, lang) => (event) => {
		if (event.target.value === ' ') return;
		const answers = question.answers;
		if (lang) {
			if (!answers[index]) answers[index] = {};
			let newAnswer = answers[index];
			newAnswer[lang] = event.target.value;
			answers[index] = newAnswer;
			setQuestion({ ...question, answers });
		}
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar pregunta' />
					<CardBody>
						<h5 className='mt-0 mb-0'>Pregunta</h5>
						<br />
						<FormControl
							style={{ width: '100%', zIndex: 100 }}
							className='mb-2'>
							<FormHelperText>{'Categoría *'}</FormHelperText>
							<CreatableSelect
								className='mb-3 mt-3'
								isClearable
								value={
									categories.find(
										({ value }) => value === question.category
									) || ''
								}
								onChange={({ value }) =>
									setQuestion({ ...question, category: value })
								}
								options={categories}
							/>
						</FormControl>
						<br />
						<MultilanguageTabBlock
							multilanguageTabContent={(lang) => (
								<>
									<br />
									<TextField
										id={`name`}
										label='Pregunta'
										value={
											(question.name && question.name[lang]) ||
											''
										}
										onChange={handleChange('name', lang)}
										InputLabelProps={{ shrink: true }}
										margin='normal'
										variant='outlined'
										required
									/>
									<br />
								</>
							)}
						/>
						<h5 className='mt-5 mb-5'>Respuestas</h5>
						<div className='d-flex'>
							<FormControlLabel
								control={
									<Checkbox
										checked={question.type === 'numeric'}
										onChange={({ target: { checked } }) => {
											setQuestion({
												...question,
												type: checked ? 'numeric' : 'test',
												numericLength: DefaultNumericLength,
											});
										}}
										name={'checkActive'}
									/>
								}
								label={
									<div>
										<span>
											{'Respuesta numérica (del 1 al '}
											<TextField
												type='number'
												style={{
													width: '50px',
													bottom: '3px',
												}}
												value={
													question.numericLength ||
													DefaultNumericLength
												}
												onChange={({ target: { value } }) =>
													setQuestion({
														...question,
														numericLength: value,
													})
												}
												InputLabelProps={{ shrink: true }}
												InputProps={{
													inputProps: { min: 2, max: 10 },
												}}
											/>
											{')'}
										</span>
									</div>
								}
							/>
						</div>
						<FormControlLabel
							control={
								<Checkbox
									checked={question.type === 'boolean'}
									onChange={({ target: { checked } }) => {
										setQuestion({
											...question,
											type: checked ? 'boolean' : 'test',
										});
									}}
									name={'checkActive'}
								/>
							}
							label={'Respuesta binaria (verdadero/falso)'}
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={question.type === 'open'}
									onChange={({ target: { checked } }) => {
										setQuestion({
											...question,
											type: checked ? 'open' : 'test',
										});
									}}
									name={'checkActive'}
								/>
							}
							label={'Respuesta abierta'}
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={question.type === 'test-multiple'}
									onChange={({ target: { checked } }) => {
										setQuestion({
											...question,
											type: checked ? 'test-multiple' : 'test',
										});
									}}
									name={'checkActive'}
								/>
							}
							label={'Respuesta múltiple'}
						/>
						<br />
						{(question.type === 'test' ||
							question.type === 'test-multiple') && (
							<>
								<h6 className='mt-5 mb-5'>Posibles respuestas</h6>
								{question.answers.map((answer, index) => (
									<MultilanguageTabBlock
										key={index}
										multilanguageTabContent={(lang) => (
											<>
												<br />
												<TextField
													id={`text`}
													label={`Respuesta ${index + 1}`}
													value={
														(answer && answer[lang]) ||
														''
													}
													onChange={handleChangeAnswer(
														index,
														lang
													)}
													InputLabelProps={{
														shrink: true,
													}}
													margin='normal'
													variant='outlined'
													required
												/>
												<Button
													onClick={() => {
														const answers =
															question.answers;
														answers.splice(index, 1);
														setQuestion({
															...question,
															answers,
														});
													}}
													variant='outlined'
													color='secondary'>
													Eliminar respuesta
												</Button>
												<br />
												<br />
											</>
										)}
									/>
								))}
								<Button
									onClick={() => {
										const answers = question.answers;
										answers.push({});
										setQuestion({ ...question, answers });
									}}
									variant='outlined'
									color='primary'>
									Añadir nueva respuesta
								</Button>
							</>
						)}
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/questions')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveQuestion()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar pregunta
				</Button>
				{questionId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								onClick={() => setOpenConfirmDialog(true)}
								variant='outlined'
								color='secondary'>
								Eliminar pregunta
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={
								'¿Estás seguro de que quieres eliminar esta pregunta?'
							}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteQuestion(questionId)
									.then((res) => {
										if (
											res.status === 204 ||
											res.status === 200
										) {
											alertSuccess({
												title: '¡Eliminada!',
												customMessage:
													'Pregunta eliminada con éxito',
											});
											history.push('/questions');
										}
									})
									.catch((error) =>
										alertError({
											error: error,
											customMessage:
												'Could not delete question.',
										})
									);
							}}
						/>
					</>
				)}
			</>
		);
}
