import {
	Button,
	FormControlLabel,
	Switch,
	TextField,
	Tooltip,
} from '@material-ui/core';
import { Map } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import { SERVER_URL } from '../../../../api';
import {
	changeStatusResource,
	deleteResource,
	getResources,
} from '../../../../api/resource';
import { getResourceTypologies } from '../../../../api/resourceTypology';
import { getNonEmpty } from '../../../../utils/helpers';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import MapDialog from '../../../components/dialogs/MapDialog';
import FiltersCard from '../../../components/filters/Filter';
import Table, {
	buttonsStyle,
	dateFormatter,
	validatedFormatter,
} from '../../../components/tables/table';

function getData(resources) {
	let data = [];

	for (let i = 0; i < resources.length; ++i) {
		const elem = {};

		elem.title = getNonEmpty(resources[i].title);
		elem.resourceTypologies = resources[i].resourceTypologies;
		elem.familyName = resources[i].family?.fullName;

		elem.address = resources[i].address;
		elem.latitude = resources[i].latitude;
		elem.longitude = resources[i].longitude;

		elem.createdAt = resources[i].createdAt;
		elem.validated = resources[i].validated;
		elem.id = resources[i]._id;

		data = data.concat(elem);
	}
	return data;
}

function getResourceTypologyData(typologies) {
	let data = typologies.map((x) => ({ ...x, title: getNonEmpty(x.title) }));

	data.sort((a, b) => {
		const titleA = a.title.toLowerCase();
		const titleB = b.title.toLowerCase();

		if (titleA < titleB) {
			return -1;
		} else if (titleA > titleB) {
			return 1;
		} else {
			return 0;
		}
	});

	return data;
}

const initialFilters = {
	resourceTypology: [],
	onlyValidated: false,
};

export default function ResourcesPage() {
	const [data, setData] = useState([]);
	const [resourceTypologies, setResourceTypologies] = useState([]);

	const [openMapDialog, setOpenMapDialog] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState({
		address: null,
		latitude: null,
		longitude: null,
	});

	const [resourceId, setResourceId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);

	const [filteredData, setFilteredData] = useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [filterOptions, setFilterOptions] = useState(initialFilters);

	const [refresh, setRefresh] = useState(false);

	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-resource/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && (
					<>
						<Tooltip title={elem?.validated ? 'Invalidar' : 'Validar'}>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setResourceId(elem);
									setOpenConfirmDialog(1);
								}}>
								{elem?.validated ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon
										style={{
											color: elem?.validated === false ? 'red' : '',
										}}
									/>
								)}
							</Button>
						</Tooltip>
						<Tooltip title='Delete'>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setResourceId(cell);
									setOpenConfirmDialog(2);
								}}>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	function typologiesFormatter(typologies) {
		return (
			<>
				<div className='d-flex justify-content-center align-items-center'>
					{typologies.map((typology) => {
						return (
							<Tooltip title={getNonEmpty(typology.title)}>
								<img
									className='mx-1'
									src={`${SERVER_URL}/${typology.iconURL}`}
									alt={''}
									width={20}></img>
							</Tooltip>
						);
					})}
				</div>
			</>
		);
	}

	function addressFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title='Ver ubicación'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setOpenMapDialog(true);
							setSelectedLocation({
								address: elem.address,
								latitude: elem.latitude,
								longitude: elem.longitude,
							});
						}}>
						<Map
							style={{
								color: elem.validated === false ? '#ff0000' : '',
							}}
						/>
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'title', text: 'Title', sort: true },
		{
			dataField: 'resourceTypologies',
			text: 'Tipología',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: typologiesFormatter,
		},
		{ dataField: 'familyName', text: 'Familia', sort: true },
		{
			dataField: 'id',
			text: 'Ubicación',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: addressFormatter,
		},
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: 'validated',
			text: 'Validado',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: validatedFormatter,
		},

		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getResourceTypologies()
			.then((res) => {
				if (res.status === 200) {
					setResourceTypologies(getResourceTypologyData(res.data));
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get resource typologies.',
				});
			});
		getResources('?getDisabled=true')
			.then((res) => {
				if (res.status === 200) {
					let data = getData(res.data);
					setData(data);
					setFilteredData(data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get resources.',
				});
			});
	}, [refresh]);

	const renderFiltersContent = () => {
		return (
			<>
				<Autocomplete
					multiple
					id='resourceTypeFilter'
					filterSelectedOptions
					disablePortal
					options={resourceTypologies}
					getOptionLabel={(option) => option.title}
					value={filterOptions.resourceTypology || []}
					onChange={(event, selected) => {
						setFilterOptions({
							...filterOptions,
							resourceTypology: selected,
						});
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							margin='normal'
							variant='outlined'
							InputLabelProps={{
								shrink: true,
							}}
							label='Resource Typology'
						/>
					)}
				/>
				<br />
				<FormControlLabel
					control={
						<Switch
							checked={filterOptions.onlyValidated}
							onChange={() =>
								setFilterOptions({
									...filterOptions,
									onlyValidated: !filterOptions.onlyValidated,
								})
							}
							name='checkActive'
						/>
					}
					label={
						filterOptions.onlyValidated
							? 'Solo validados'
							: 'Todos validados/invalidados'
					}
				/>
			</>
		);
	};

	const handleClearFilters = () => {
		setFilterOptions(initialFilters);
		setRefresh(true);
	};

	const handleSearch = async () => {
		if (!data.length) return;
		setFilteredData(
			data.filter((item) => {
				let filter = true;
				if (
					filterOptions.resourceTypology &&
					filterOptions.resourceTypology.length
				)
					filter =
						filter &&
						filterOptions.resourceTypology
							.map((x) => x._id)
							.some((x) =>
								item.resourceTypologies.map((y) => y._id).includes(x)
							);
				if (filterOptions.onlyValidated) filter = filter && item.validated;

				if (filter) return item;
				return false;
			})
		);
	};

	return (
		<>
			<Card>
				<CardHeader title='Resource list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() =>
								history.push({ pathname: '/edit-resource', state: { data } })
							}>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>
					<Table data={filteredData} columns={columns} />
					<ConfirmDialog
						title={`¿Estás seguro de que quieres ${
							resourceId?.validated ? 'denegar' : 'validar'
						} este recurso?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusResource(resourceId.id, !resourceId?.validated)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												resourceId?.validated ? 'Denegado' : 'Validado'
											}`,
											customMessage: `Recurso ${
												resourceId?.validated ? 'denegado' : 'validado'
											} con éxito`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `No se pudo ${
											resourceId?.validated ? 'denegar' : 'validar'
										} el recurso.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={'Are you sure you want to remove this resource?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteResource(resourceId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Resource removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove resource.',
									});
								});
						}}
					/>
					<MapDialog
						open={openMapDialog}
						setOpen={setOpenMapDialog}
						title={selectedLocation?.address || ''}
						latitude={selectedLocation?.latitude}
						longitude={selectedLocation?.longitude}></MapDialog>
				</CardBody>
			</Card>
		</>
	);
}
