const LITERALS = {
	user: 'Usuarios',
	enterprise: 'Empresas',
};

const COLUMNS = {
	name: {
		dataField: 'name',
		text: 'Tipo',
		formatter: (cell) => LITERALS[cell] || cell,
	},
	messages: {
		dataField: 'messages',
		text: 'Cantidad de mensajes',
		sort: true,
		headerAlign: 'center',
		align: 'center',
	},
	visits: {
		dataField: 'visits',
		text: 'Visitas',
		sort: true,
		headerAlign: 'center',
		align: 'center',
	},
	users: {
		dataField: 'users',
		text: 'Cantidad de usuarios',
		sort: true,
		headerAlign: 'center',
		align: 'center',
	},
	views: {
		dataField: 'views',
		text: 'Vistas',
		sort: true,
		headerAlign: 'center',
		align: 'center',
	},
	percentage: {
		dataField: 'percentage',
		text: 'Porcentaje del total',
		sort: true,
		headerAlign: 'center',
		align: 'center',
	},
};

export const getColumns = (data) => {
	return Object.keys(data).map((key) => {
		const col = COLUMNS[key];
		if (col) return col;
	});
};
