import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React from "react";
import {
	GoogleMap,
	Marker,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import { GOOGLE_MAPS_API_KEY } from "../../../utils/constants";

const MapDialog = (props) => {
	const { open, setOpen, title, latitude, longitude } = props;

	const Map = compose(
		withProps({
			googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
			loadingElement: <div style={{ height: "100%" }} />,
			containerElement: <div className="dialog-map" />,
			mapElement: <div style={{ height: "100%" }} />,
		}),
		withScriptjs,
		withGoogleMap
	)((props) => (
		<GoogleMap
			defaultZoom={13}
			defaultCenter={{
				lat: parseFloat(latitude),
				lng: parseFloat(longitude),
			}}
		>
			<Marker
				position={{
					lat: parseFloat(latitude),
					lng: parseFloat(longitude),
				}}
			/>
		</GoogleMap>
	));
	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			aria-labelledby="confirm-dialog"
			className="backdrop"
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent>
				<Map></Map>
			</DialogContent>
			<DialogActions>
				<Button
					className="mt-3"
					variant="outlined"
					onClick={() => {
						setOpen(false);
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default MapDialog;
