import { authClient, API } from "../index";

// Count all surveys
export const countSurveys = () => {
  return authClient().get(`${API}/survey/count`);
};

// Get all surveys
export const getSurveys = (query) => {
  return authClient().get(`${API}/survey${query || ""}`);
};

// Get survey by id
export const getSurveyById = (id) => {
  return authClient().get(`${API}/survey/${id}`);
};

// Update survey
export const updateSurvey = async (id, survey) => {
  return authClient().put(`${API}/survey/${id}`, survey);
};

// Create survey
export const postSurvey = async (survey) => {
  return authClient().post(`${API}/survey`, survey);
};

// Change survey status
export const changeStatusSurvey = async (id, active) => {
  return authClient().put(`${API}/survey/change-status/${id}`, { active });
};

// Delete survey
export const deleteSurvey = (id) => {
  return authClient().delete(`${API}/survey/${id}`);
};

// Send survey
export const sendSurvey = (id) => {
  return authClient().get(`${API}/survey/send/${id}`);
};

// Get survey report
export const getSurveyReport = (id) =>
  authClient().get(`${API}/survey/report/${id}`);

// Get survey report with details
export const getSurveyEntriesWithDetails = (surveyId) => {
  return authClient().get(`${API}/survey-entry/by-survey-details/${surveyId}`);
};
