const COLUMNS = {
	name: { dataField: 'name', text: 'Respuesta' },
	entries: { dataField: 'entries', text: 'Cantidad de respuestas' },
}

export const getColumns = (data) => {
	return Object.keys(data).map((key) => {
		const col = COLUMNS[key]
		if (col) return col
	})
}
