import { Link } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
	changeStatusThread,
	deleteThread,
	getThreadById,
} from "../../../../api/thread";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";

function ValidateThreadsPage() {
	const threadId = useParams().id;
	const history = useHistory();

	const [removeThread, setRemoveThread] = useState(null);
	const [validateThread, setValidateThread] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [thread, setThread] = useState(null);
	const [loading, setLoading] = useState(false);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getThreadById(threadId)
			.then((res) => {
				if (res.status === 200) {
					let thread = res.data;
					setThread(thread);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get thread.",
				});
				history.push("/threads");
			});
	}, [threadId]);

	useEffect(() => {
		/*if (thread?.validated || thread?.validated === false) {
			alertSuccess({
				allowOutsideClick: false,
				customMessage:
					"Este hilo ya ha sido validado. Para consultar su estado o actualizarlo dirígete al Backoffice.",
			}).then((res) => {
				if (res.isConfirmed) history.push("/threads");
			});
		}*/
	}, [thread]);

	return (
		<div className="d-flex flex-column flex-root">
			{/*begin::Login*/}
			<div
				className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
				id="kt_login"
			>
				{/*begin::Aside*/}
				<div
					className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
					style={{
						backgroundImage: `url(${toAbsoluteUrl(
							"/media/bg/bg-auth.jpg"
						)})`,
					}}
				>
					{/*begin: Aside Container*/}
					<div className="d-flex flex-row-fluid flex-column justify-content-between">
						{/* start:: Aside header */}
						<Link
							to="/"
							className="flex-column-auto mt-5 pb-lg-0 pb-10"
						>
							<Image
								alt="logo"
								className="max-h-70px max-w-250px"
								src={toAbsoluteUrl(
									"/media/logo/logo-white.png"
								)}
							/>
						</Link>
						{/* end:: Aside header */}

						{/* start:: Aside content */}
						<div className="flex-column-fluid d-flex flex-column justify-content-center">
							<h3 className="font-size-h1 mb-5 text-white">
								Welcome!
							</h3>
						</div>
						{/* end:: Aside content */}

						{/* start:: Aside footer for desktop */}
						<div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
							<div className="opacity-70 font-weight-bold	text-white">
								&copy; 2023
							</div>
						</div>
						{/* end:: Aside footer for desktop */}
					</div>
					{/*end: Aside Container*/}
				</div>
				{/*begin::Aside*/}

				{/*begin::Content*/}
				<div className="d-flex flex-column flex-row-fluid position-relative px-7 overflow-hidden">
					<div className="d-flex flex-column-fluid flex-center mt-5 mt-lg-0">
						<div>
							<div>
								<div>
									<h3 className="text-center font-size-h1">
										{thread?.title}
									</h3>
									<br />
									<div className="font-size-h6">
										<div className="row d-flex align-items-start">
											<div className="col-md-4">
												<strong>Tópico: </strong>
											</div>
											<div className="col-md-8">
												<p>
													{getNonEmpty(
														thread?.topic?.title
													)}
												</p>
											</div>
										</div>
										<div className="row d-flex align-items-start">
											<div className="col-md-4">
												<strong>Mensaje: </strong>
											</div>
											<div className="col-md-8">
												<p
													dangerouslySetInnerHTML={{
														__html: thread?.text,
													}}
												></p>
											</div>
										</div>
										<div className="row d-flex align-items-start">
											<div className="col-md-4">
												<strong>Autor: </strong>
											</div>
											<div className="col-md-8">
												<p>
													{
														thread?.createdBy
															?.fullName
													}
												</p>
											</div>
										</div>
									</div>
									<br />
									<div className="d-flex justify-content-md-center justify-content-between align-items-center">
										<button
											onClick={() => {
												if (thread?.validated)
													setValidateThread(false);
												else setValidateThread(true);
												setOpenConfirmDialog(1);
											}}
											className={`btn btn-primary font-weight-bold px-9 py-4 my-3 align-self-center mr-md-5 ${
												thread?.validated
													? "text-danger"
													: "text-primary"
											}`}
											style={{
												backgroundColor: "transparent",
												borderColor: thread?.validated
													? "#f6485b"
													: "$primary",
											}}
										>
											<span>
												{thread?.validated
													? "Invalidar"
													: "Validar"}
											</span>
											{loading && (
												<span className="ml-3 spinner spinner-white" />
											)}
										</button>
										<button
											onClick={() => {
												setRemoveThread(true);
												setOpenConfirmDialog(2);
											}}
											style={{ borderColor: "#f6485b" }}
											className={
												"btn btn-primary font-weight-bold px-9 py-4 my-3 align-self-center ml-md-5 bg-danger"
											}
										>
											<span>Eliminar</span>
											{loading && (
												<span className="ml-3 spinner spinner-white" />
											)}
										</button>
									</div>
								</div>
							</div>

							<ConfirmDialog
								title={`¿Estás seguro de que quieres ${
									validateThread
										? "validar y hacer público"
										: "invalidar y no publicar"
								} este hilo?`}
								open={openConfirmDialog === 1}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									setLoading(true);
									changeStatusThread(threadId, validateThread)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												setLoading(false);
												alertSuccess({
													allowOutsideClick: false,
													title: validateThread
														? "¡Validado!"
														: "¡Denegado!",
													customMessage: `Hilo ${
														validateThread
															? "validado"
															: "denegado"
													} con éxito.`,
												}).then((res) => {
													if (res.isConfirmed)
														history.push(
															"/threads"
														);
												});
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"No se pudo actualizar el hilo.",
											});
										});
								}}
							/>
							<ConfirmDialog
								title={`¿Estás seguro de que quieres eliminar este hilo?`}
								open={openConfirmDialog === 2}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									setLoading(true);
									deleteThread(threadId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												setLoading(false);
												alertSuccess({
													allowOutsideClick: false,
													title: "Eliminado",
													customMessage: `Hilo eliminado con éxito.`,
												}).then((res) => {
													if (res.isConfirmed)
														history.push(
															"/threads"
														);
												});
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"No se pudo eliminar el hilo.",
											});
										});
								}}
							/>
						</div>
					</div>
					{/*end::Content body*/}

					{/* begin::Mobile footer */}
					<div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
						<div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
							&copy; 2023
						</div>
						{/* <div className="d-flex order-1 order-sm-2 my-2">
							<Link to="/terms" className="text-dark-75 text-hover-primary">
			  Privacidad
							</Link>
							<Link
								to="/terms"
								className="text-dark-75 text-hover-primary ml-4"
							>
			  Legal
							</Link>
							<Link
								to="/terms"
								className="text-dark-75 text-hover-primary ml-4"
							>
			  Contacto
							</Link>
						</div> */}
					</div>
					{/* end::Mobile footer */}
				</div>
				{/*end::Content*/}
			</div>
			{/*end::Login*/}
		</div>
	);
}

export default ValidateThreadsPage;
