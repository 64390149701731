import {
	Button,
	FormControlLabel,
	Switch,
	TextField,
	Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import {
	changeStatusThread,
	deleteThread,
	getThreads,
} from '../../../../api/thread';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import Table, {
	buttonsStyle,
	dateFormatter,
	substringFormatter,
	validatedFormatter,
} from '../../../components/tables/table';
import { ToggleOff, ToggleOn } from '@material-ui/icons';
import FiltersCard from '../../../components/filters/Filter';
import { getTopics } from '../../../../api/topic';
import { getRegions } from '../../../../api/region';
import { Autocomplete } from '@material-ui/lab';
import { getLabel, getNonEmpty } from '../../../../utils/helpers';

function getData(threads, regions) {
	let data = [];
	for (let i = 0; i < threads.length; ++i) {
		const thread = threads[i];
		const elem = {};
		elem.title = thread.title;
		elem.text = thread.text;
		elem.topicTitle = getNonEmpty(thread.topic?.title);
		elem.region = getLabel(thread.region, regions, '_id', 'name');
		elem.topic = thread.topic;
		elem.replies = thread.numReplies;
		elem.createdAt = thread.createdAt;
		elem.validated = thread.validated;
		elem.id = thread._id;
		data = data.concat(elem);
	}
	return data;
}

const initialFilters = { topic: [], onlyValidated: false };

export default function ThreadsPage() {
	const [data, setData] = useState([]);
	const [topics, setTopics] = useState([]);

	const [selectedThread, setSelectedThread] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const [filteredData, setFilteredData] = useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [filterOptions, setFilterOptions] = useState(initialFilters);

	const [refresh, setRefresh] = useState(false);

	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);

		return (
			<>
				<Tooltip title='Reply'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/view-thread/' + cell)}>
						<ReplyIcon />
					</Button>
				</Tooltip>
				<Tooltip title={elem?.validated ? 'Invalidar' : 'Validar'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setSelectedThread(elem);
							setOpenConfirmDialog(1);
						}}>
						{elem?.validated ? (
							<ToggleOff />
						) : (
							<ToggleOn
								style={{
									color: elem?.validated === false ? 'red' : '',
								}}
							/>
						)}
					</Button>
				</Tooltip>
				<Tooltip title='Delete'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setSelectedThread(elem);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'topicTitle', text: 'Tópico', sort: true },
		{ dataField: 'title', text: 'Título', sort: true },
		{
			dataField: 'text',
			text: 'Texto',
			sort: true,
			formatter: substringFormatter,
		},
		{
			dataField: 'replies',
			text: 'Respuestas',
			sort: true,
			align: 'center',
			headerAlign: 'center',
		},
		{ dataField: 'region', text: 'Región', sort: true },
		{
			dataField: 'createdAt',
			text: 'Creado',
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: 'validated',
			text: 'Validado',
			headerAlign: 'center',
			align: 'center',
			formatter: validatedFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getTopics()
			.then((res) => {
				if (res.status === 200) setTopics(res.data);
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get topics.' })
			);
		getRegions()
			.then((resRegions) => {
				if (resRegions.status === 200) {
					getThreads('?getDisabled=true')
						.then((resThreads) => {
							if (resThreads.status === 200) {
								let data = getData(resThreads.data, resRegions.data);
								setData(data, resRegions.data);
								setFilteredData(data);
								setRefresh(false);
							}
						})
						.catch((error) =>
							alertError({
								error: error,
								customMessage: 'Could not get threads.',
							})
						);
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get events.' })
			);
	}, [refresh]);

	const renderFiltersContent = () => {
		return (
			<>
				<Autocomplete
					multiple
					id='topicTypeFilter'
					filterSelectedOptions
					disablePortal
					options={topics}
					getOptionLabel={(option) => getNonEmpty(option.title)}
					value={filterOptions.topic || []}
					onChange={(event, selected) =>
						setFilterOptions({ ...filterOptions, topic: selected })
					}
					renderInput={(params) => (
						<TextField
							{...params}
							margin='normal'
							variant='outlined'
							InputLabelProps={{
								shrink: true,
							}}
							label='Tópico'
						/>
					)}
				/>
				<br />
				<FormControlLabel
					control={
						<Switch
							checked={filterOptions.onlyValidated}
							onChange={() =>
								setFilterOptions({
									...filterOptions,
									onlyValidated: !filterOptions.onlyValidated,
								})
							}
							name='checkActive'
						/>
					}
					label={
						filterOptions.onlyValidated
							? 'Solo validados'
							: 'Todos validados/invalidados'
					}
				/>
			</>
		);
	};

	const handleClearFilters = () => {
		setFilterOptions(initialFilters);
		setRefresh(true);
	};

	const handleSearch = async () => {
		if (!data.length) return;
		setFilteredData(
			data.filter((item) => {
				let filter = true;
				if (filterOptions.topic && filterOptions.topic.length)
					filter =
						filter &&
						filterOptions.topic.map((x) => x._id).includes(item.topic?._id);
				if (filterOptions.onlyValidated) filter = filter && item.validated;
				if (filter) return item;
				return false;
			})
		);
	};

	return (
		<>
			<Card>
				<CardHeader title='Listado de hilos'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/add-thread')}>
							Añadir hilo
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>
					<Table data={filteredData} columns={columns} />
					<ConfirmDialog
						title={`¿Estás seguro de que quieres ${
							selectedThread?.validated ? 'denegar' : 'validar'
						} este hilo?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusThread(selectedThread.id, !selectedThread?.validated)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedThread?.validated ? 'Denegado' : 'Validado'
											}`,
											customMessage: `Hilo ${
												selectedThread?.validated ? 'denegado' : 'validado'
											} con éxito`,
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage: `No se pudo ${
											selectedThread?.validated ? 'denegar' : 'validar'
										} el hilo.`,
									})
								);
						}}
					/>
					<ConfirmDialog
						title={'¿Estás seguro de que quieres eliminar este hilo?'}
						children={'Esto también eliminará todos sus mensajes'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteThread(selectedThread?.id)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Thread removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage: 'Could not remove thread.',
									})
								);
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
