import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, { buttonsStyle } from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getUsers, deleteUser, changeStatusUser } from '../../../../api/user';
import { Button, Tooltip, Switch, FormControlLabel } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';
import FiltersCard from '../../../components/filters/Filter';

function getData(families) {
	let data = [];
	for (let i = 0; i < families.length; ++i) {
		if (families[i].role === 'user') {
			const elem = {};
			elem.name = families[i].fullName;
			elem.email = families[i].email;
			elem.id = families[i]._id;
			elem.active = families[i].active;
			data = data.concat(elem);
		}
	}
	return data;
}

const initialFilters = { active: false };

export function FamiliesPage() {
	const [data, setData] = useState([]);
	const [selectedFamily, setSelectedFamily] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [users, setUsers] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [filterOptions, setFilterOptions] = useState(initialFilters);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title='Editar'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-family/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && (
					<>
						<Tooltip title={elem?.active ? 'Desactivar' : 'Activar'}>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setSelectedFamily(elem);
									setOpenConfirmDialog(1);
								}}>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: 'red' }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title='Eliminar'>
							<Button
								style={buttonsStyle}
								size='small'
								onClick={() => {
									setSelectedFamily(cell);
									setOpenConfirmDialog(2);
								}}>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Nombre', sort: true },
		{ dataField: 'email', text: 'Email', sort: true },
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setFilteredData(res.data);
					setUsers(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'No se han podido obtener las familias.',
				});
			});
	}, [refresh]);

	const handleSearch = async () => {
		if (!data.length) return;
		setFilteredData(
			data.filter((item) => {
				let filter = true;
				if (filterOptions.active) filter = filter && item.active;
				if (filter) return item;
				return false;
			})
		);
	};

	const handleClearFilters = () => {
		setFilterOptions(initialFilters);
		setRefresh(true);
	};

	const renderFiltersContent = () => {
		return (
			<>
				<br />
				<FormControlLabel
					control={
						<Switch
							checked={filterOptions.active}
							onChange={() =>
								setFilterOptions({
									...filterOptions,
									active: !filterOptions.active,
								})
							}
							name='checkActive'
						/>
					}
					label={
						filterOptions.active
							? 'Solo activas'
							: 'Todas (activas e inactivas)'
					}
				/>
			</>
		);
	};

	return (
		<>
			<Card>
				<CardHeader title='Listado de familias'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-family')}>
							Añadir
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>

					<Table data={getData(filteredData, users)} columns={columns} />

					<ConfirmDialog
						title={`¿Estás seguro de que quieres ${
							selectedFamily?.active ? 'desactivar' : 'activar'
						} esta família?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							changeStatusUser(
								selectedFamily._id,
								!selectedFamily?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedFamily?.active
													? '¡Desactivada!'
													: '¡Activada!'
											}`,
											customMessage: `Família ${
												selectedFamily?.active
													? 'desactivada'
													: 'activada'
											} con éxito.`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `No se ha podido ${
											selectedFamily?.active
												? 'desactivar'
												: 'activar'
										} la família.`,
									});
								})
						}
					/>
					<ConfirmDialog
						title={'¿Estás seguro de que quieres eliminar esta família?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							deleteUser(selectedFamily)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: '¡Eliminado!',
											customMessage:
												'Família eliminada con éxito.',
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage:
											'No se ha podido eliminar la família.',
									})
								)
						}
					/>
				</CardBody>
			</Card>
		</>
	);
}
