import { authClient, API } from '../index';

// Count all regions
export const countRegions = () => {
	return authClient().get(`${API}/region/count`);
};

// Get all regions
export const getRegions = () => {
	return authClient().get(`${API}/region`);
};

// Get region by id
export const getRegionById = (id) => {
	return authClient().get(`${API}/region/${id}`);
};

// Update region
export const updateRegion = (id, region) => {
	return authClient().put(`${API}/region/${id}`, region);
};

// Create region
export const postRegion = (region) => {
	return authClient().post(`${API}/region`, region);
};

// Delete region
export const deleteRegion = (id) => {
	return authClient().delete(`${API}/region/${id}`);
};
