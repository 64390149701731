import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { validateEmail } from '../../api/user/index';
import '../../customStyles/landing.css';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';

function ValidateEmailPage() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');

	const [isSuccess, setIsSuccess] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, showError] = useState(false);

	const disableLoading = () => setLoading(false);

	const MESSAGES = {
		success: '¡Correo verificado!',
		error: 'Petición inválida',
		error_text:
			'El enlace de verificación no es válido. Puede que haya expirado o que ya se haya utilizado.',
	};

	useEffect(() => {
		async function validate() {
			if (token) {
				try {
					await validateEmail(token);
					setIsSuccess(true);
					disableLoading();
				} catch (error) {
					showError(true);
				}
			} else {
			}
		}
		validate();
	}, [token]);

	return (
		<>
			<div className='centered-container'>
				{loading && <span className='ml-3 spinner spinner-white' />}
				<div className='text-center mb-lg-10'>
					<img
						alt='logo'
						className='max-h-77px max-w-275px'
						src={toAbsoluteUrl('/media/logo/logo.png')}
					/>
					<h1 className='display-5 mt-20'>
						{isSuccess ? MESSAGES.success : error ? MESSAGES.error : ''}
					</h1>
					{error && <h3 className='display-5 mt-20'>{MESSAGES.error_text}</h3>}
				</div>
				<div>
					<h2 className='display-8 align-self-center text-center font-weight-bold mt-10 mb-10'>
						{'Descárgate la app'}
					</h2>
					<div className='store-container'>
						<a
							href='https://play.google.com/store/apps/details?id=com.proxare.android'
							target='_blank'
							rel='noreferrer'>
							<button
								id='kt_login_signin_submit'
								type='button'
								className={
									'store-button android-button font-weight-bold px-9 py-4'
								}>
								<AndroidIcon />
								{'Google Play'}
							</button>
						</a>
						<a
							href='https://apps.apple.com/vn/app/proxare/id6470037589'
							target='_blank'
							rel='noreferrer'>
							<button
								id='kt_login_signin_submit'
								type='button'
								className={
									'store-button ios-button font-weight-bold px-9 py-4'
								}>
								<AppleIcon />
								{'App Store'}
							</button>
						</a>
					</div>
				</div>
			</div>
		</>
	);
}

export default ValidateEmailPage;
