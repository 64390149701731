import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { Image } from 'react-bootstrap'
import '../../../customStyles/bootstrap_table.css'
import CheckBox from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import { SERVER_URL } from '../../../api'
import { Cancel, CheckCircle, VerifiedUser } from '@material-ui/icons'

const { SearchBar, ClearSearchButton } = Search

export const buttonsStyle = {
	maxWidth: '30px',
	maxHeight: '30px',
	minWidth: '30px',
	minHeight: '30px',
	marginLeft: '1em',
}

export function validatedFormatter(validated) {
	return validated ? (
		<CheckCircle />
	) : validated === false ? (
		<Cancel style={{ color: '#ff0000' }} />
	) : (
		<div></div>
	)
}

export function booleanFormatter(cell) {
	return cell ? (
		<CheckBox style={{ color: '#000000' }} />
	) : (
		<CheckBoxOutlineBlank style={{ color: '#000000' }} />
	)
}

export function imageFormatter(imageUrl) {
	return imageUrl ? (
		<Image src={SERVER_URL + '/' + imageUrl} width={40}></Image>
	) : (
		<div></div>
	)
}

export function longTextFormatter(cell) {
	return (
		<td className='truncate'>
			<div dangerouslySetInnerHTML={{ __html: cell }}></div>
		</td>
	)
}

function extractContent(s) {
	if (!s) return '----'
	var span = document.createElement('span')
	span.innerHTML = s
	return span.textContent || span.innerText
}

export function substringFormatter(cell) {
	const text = extractContent(cell)
	return text.length > 50 ? text.substring(0, 50) + '...' : text
}

const buildDate = (date) => {
	if (Object.prototype.toString.call(date) !== '[object Date]') return
	const displayDate = `${date.getDate()}/${date.getMonth() +
		1}/${date.getFullYear()}  ${date.getHours()}:${String(
		date.getMinutes()
	).padStart(2, '0')}`
	return displayDate
}

export function dateFormatter(cell) {
	let date = new Date(cell)
	date = buildDate(date)

	return date
}

const customTotal = (from, to, size) => (
	<span
		className='react-bootstrap-table-pagination-total'
		style={{ paddingLeft: '5px' }}>
		Showing {from}-{to} of {size} results
	</span>
)

const pagination = paginationFactory({
	page: 1,
	sizePerPage: 10,
	lastPageText: '>>',
	firstPageText: '<<',
	nextPageText: '>',
	prePageText: '<',
	showTotal: true,
	paginationTotalRenderer: customTotal,
	alwaysShowAllBtns: false,
	onPageChange: function(page, sizePerPage) {},
	onSizePerPageChange: function(page, sizePerPage) {},
})

const rowClasses = (row) =>
	row.active === false || row.validated === false ? 'inactive-row' : ''

export default function Table({
	data,
	columns,
	showSearch = true,
	keyField = 'id',
	title = '',
	...tableProps
}) {
	return (
		<ToolkitProvider
			bootstrap4
			keyField={keyField}
			data={data}
			columns={columns}
			search>
			{(props) => (
				<div>
					{showSearch && (
						<>
							<SearchBar placeholder='Search' {...props.searchProps} />
							<ClearSearchButton
								className='ml-3'
								{...props.searchProps}
							/>
						</>
					)}
					<br />
					<br />
					{title && <h5>{title}</h5>}
					<BootstrapTable
						wrapperClasses='table-responsive'
						classes='table table-head-custom table-vertical-center overflow-hidden'
						bordered={false}
						// remote
						pagination={data.length > 10 ? pagination : null}
						striped
						rowClasses={rowClasses}
						{...tableProps}
						{...props.baseProps}
					/>
				</div>
			)}
		</ToolkitProvider>
	)
}
