import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
	FormControl,
	FormHelperText,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import {
	deleteSurvey,
	getSurveyById,
	postSurvey,
	updateSurvey,
	sendSurvey,
} from '../../../../api/survey';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getQuestions } from '../../../../api/question';
import { getCategories } from '../../../../api/category';
import { getNonEmpty } from '../../../../utils/helpers';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

const getDataSelect = (elements) =>
	elements.map(({ _id, name, category }) => ({
		value: _id,
		label: getNonEmpty(name),
		category: category?._id || category,
	}));

const getQuestionsByCategory = (categories, questions) => {
	let obj = {};
	categories.forEach(({ _id }) => {
		obj[_id] = questions[_id].map((question) => question._id);
	});
	return obj;
};

export function EditSurveysPage() {
	const [survey, setSurvey] = useState({
		name: {},
		questions: [],
		maxEntries: 100,
		entries: 0,
	});
	const [surveyQuests, setSurveyQuests] = useState({});
	const [categories, setCategories] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const surveyId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getCategories()
			.then((res) => {
				if (res.status === 200) setCategories(getDataSelect(res.data));
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get categories.' })
			);
		getQuestions()
			.then((res) => {
				if (res.status === 200) setQuestions(getDataSelect(res.data));
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get questions.' })
			);
		if (!surveyId) {
			disableLoadingData();
			return;
		}
		getSurveyById(surveyId)
			.then((res) => {
				if (res.status === 200) {
					const { survey, categories, questions } = res.data;
					setSurvey(survey);
					setSurveyQuests(getQuestionsByCategory(categories, questions));
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get survey.' });
				history.push('/surveys');
			});
	}, [surveyId, disableLoadingData, history]);

	function saveSurvey() {
		const data = { ...survey, questions: Object.values(surveyQuests).flat() };
		if (!surveyId)
			postSurvey(data)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: '¡Guardada!',
							customMessage: 'Encuesta creada con éxito.',
						});
						history.push('/surveys');
					}
				})
				.catch((error) =>
					alertError({ error: error, customMessage: 'Could not save survey.' })
				);
		else
			updateSurvey(surveyId, data)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: '¡Guardado!',
							customMessage: 'Cambios guardados con éxito.',
						});
						history.push('/surveys');
					}
				})
				.catch((error) =>
					alertError({ error: error, customMessage: 'Could not save changes.' })
				);
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!survey[element]) survey[element] = {};
			let newSurvey = survey[element];
			newSurvey[lang] = event.target.value;
			setSurvey({ ...survey, [element]: newSurvey });
		} else setSurvey({ ...survey, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Editar encuesta' />
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={(lang) => (
								<>
									<br />
									<TextField
										id={`name`}
										label='Nombre'
										value={(survey.name && survey.name[lang]) || ''}
										onChange={handleChange('name', lang)}
										InputLabelProps={{ shrink: true }}
										margin='normal'
										variant='outlined'
										required
									/>
								</>
							)}
						/>
						<h5 className='mt-5 mb-5'>Preguntas de la encuesta</h5>
						{categories.map(({ value, label }, index) => (
							<FormControl
								key={value}
								style={{ width: '100%' }}
								className='ml-2'>
								<FormHelperText>
									<span>{'Elige las preguntas de la categoría'}</span>
									<strong
										style={{
											color: 'black',
										}}>{` ${label.toUpperCase()}`}</strong>
								</FormHelperText>
								<CreatableSelect
									className={`mb-3 mt-3 zindex-${categories.length -
										index +
										1}`}
									isClearable
									isMulti
									value={questions.filter((q) =>
										surveyQuests[value]?.includes(q.value)
									)}
									onChange={(event) => {
										if (event && event.length > 0)
											setSurveyQuests({
												...surveyQuests,
												[value]: event.map((e) => e.value),
											});
										else setSurveyQuests({ ...surveyQuests, [value]: [] });
									}}
									options={questions.filter(
										(option) =>
											option.category === value &&
											!surveyQuests[value]?.includes(option.value)
									)}
								/>
							</FormControl>
						))}
						<h5 className='mt-5 mb-5'>Programación de la encuesta</h5>
						<FormHelperText>
							<span>
								{
									'La encuesta se cerrará automáticamente cuando se alcance el límite de entradas.'
								}
							</span>
							<p>
								<strong style={{ color: 'black' }}>
									{`Actualmente hay ${survey.entries} entradas.`}
								</strong>
							</p>
						</FormHelperText>
						<TextField
							id={`maxEntries`}
							error={typeof survey.maxEntries !== 'number'}
							label='Límite de entradas'
							value={survey.maxEntries}
							onChange={({ target: { value } }) =>
								setSurvey({ ...survey, maxEntries: parseInt(value) })
							}
							InputLabelProps={{ shrink: true }}
							margin='normal'
							variant='outlined'
							type='number'
							inputMode='numeric'
							required
						/>
					</CardBody>
				</Card>
				<Button
					onClick={() => history.push('/surveys')}
					variant='outlined'
					style={{ marginRight: '20px' }}>
					Atrás
				</Button>
				<Button
					onClick={() => saveSurvey()}
					variant='outlined'
					color='primary'
					style={{ marginRight: '20px' }}>
					Guardar encuesta
				</Button>
				{surveyId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								onClick={() => setOpenConfirmDialog(1)}
								variant='outlined'
								color='secondary'
								style={{ marginRight: '20px' }}>
								Eliminar encuesta
							</Button>
							<Button
								onClick={() => setOpenConfirmDialog(2)}
								variant='outlined'
								color='primary'>
								Enviar encuesta
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={'¿Estás seguro de que quieres eliminar esta encuesta?'}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteSurvey(surveyId)
									.then((res) => {
										if (res.status === 204 || res.status === 200) {
											alertSuccess({
												title: '¡Eliminada!',
												customMessage: 'Encuesta eliminada con éxito',
											});
											history.push('/surveys');
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage: 'Could not delete survey.',
										});
									});
							}}
						/>
						<ConfirmDialog
							title={
								'¿Estás seguro de que quieres enviar esta encuesta a todos los usuarios que aún no la han respondido?'
							}
							open={openConfirmDialog === 2}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								sendSurvey(surveyId)
									.then((res) => {
										if (res.status === 204 || res.status === 200) {
											alertSuccess({
												title: 'Enviada!',
												customMessage: 'Encuesta enviada con éxito',
											});
											history.push('/surveys');
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage: 'Could not send survey.',
										});
									});
							}}
						/>
					</>
				)}
			</>
		);
}
