import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@material-ui/core';
import { getNonEmpty } from '../../../utils/helpers';

const ResourcesDialog = (props) => {
	const { data, open, close } = props;

	const Resource = ({ res, distance }) => {
		let title = res.title;
		if (typeof title === 'object') title = getNonEmpty(title);
		return (
			<div key={res.id} className='bg-dark-o-15 rounded p-md-6 m-6'>
				<p>
					<b>{title}</b>
				</p>
				<span>
					{`a ${distance} metros`}
					<span className='font-italic'>{` (${res.address})`}</span>
				</span>
			</div>
		);
	};

	return (
		<Dialog
			open={open}
			onClose={close}
			aria-labelledby='confirm-dialog'
			className='backdrop'>
			<DialogTitle id='confirm-dialog'>
				{'Cerca de esta ubicación hay los siguientes recursos:'}
			</DialogTitle>
			<DialogContent>{data.map(Resource)}</DialogContent>
			<DialogActions>
				<Button variant='outlined' color='primary' onClick={close}>
					Cerrar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ResourcesDialog;
